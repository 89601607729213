import {DEMO_PORTAL_CLEINT_CONFIG} from '../config/constants';

export const getOptionsFromList = clientName => {
    for (const client of Object.keys(DEMO_PORTAL_CLEINT_CONFIG)) {
        if (clientName.toLowerCase().includes(client.toLowerCase())) {
            return DEMO_PORTAL_CLEINT_CONFIG[client]['options'];
        }
    }
    return  [];
};
