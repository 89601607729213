import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';

const DebounceRender = props => {
    const {
        timeout,
        children,
        ...rest
    } = props;

    const [readyToBounce, setReadyToBounce] = useState(false);
    const ref = useRef();

    useEffect(
        () => {
            setReadyToBounce(false);
            if (ref.current) {
                clearTimeout(ref.current);
            }
            ref.current = setTimeout(
                () => {
                    setReadyToBounce(true);
                    return () => {
                        clearTimeout(ref.current);
                    };
                },
                timeout,
            );
        },
        [rest ? JSON.stringify(rest) : []],
    );

    return (
        <>
            {React.cloneElement(children, { ...rest, readyToBounce })}
        </>
    );
};

DebounceRender.propTypes = {
    timeout: PropTypes.number,
    children: PropTypes.any,
};

DebounceRender.defaultProps = {
    timeout: 700,
};

export default DebounceRender;
