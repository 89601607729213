import {
    destinationDefaults,
    destinations,
    customDestination,
    customSource,
    TOKEN_ENV,
    defaultWebAppEnabled,
    TYPE_ONE,
} from 'config/constants';
import { getAnyProperty, supportedCurrencies } from 'util.js';

const bank = (state = destinationDefaults.bank, action) => {
    if (!destinations[state]) {
        return getAnyProperty(destinations);
    }
    switch (action.type) {
        case 'CHANGE_DESTINATION_BANK':
            return action.bank;
        default:
            return state;
    }
};

const method = (state = destinationDefaults.method, action, bank) => {
    if (!destinations[bank][state]) {
        return getAnyProperty(destinations[bank]);
    }
    switch (action.type) {
        case 'CHANGE_DESTINATION_BANK':
            if (!destinations[bank][state]) {
                return getAnyProperty(destinations[bank]);
            }
            return state;
        case 'CHANGE_DESTINATION_METHOD':
            return action.method;
        default:
            return state;
    }
};

const country = (state = destinationDefaults.country, action, bank, method) => {
    if (!destinations[bank][method][state]) {
        return getAnyProperty(destinations[bank][method]);
    }
    switch (action.type) {
        case 'CHANGE_DESTINATION_BANK':
        case 'CHANGE_DESTINATION_METHOD':
            if (!destinations[bank][method][state]) {
                return getAnyProperty(destinations[bank][method]);
            }
            return state;
        case 'CHANGE_DESTINATION_COUNTRY':
            return action.country;
        default:
            return state;
    }
};

const currency = (state = destinationDefaults.currency, action, bank, method, country) => {
    if (!destinations[bank][method][country][state]) {
        return getAnyProperty(destinations[bank][method][country]);
    }
    switch (action.type) {
        case 'CHANGE_DESTINATION_BANK':
        case 'CHANGE_DESTINATION_METHOD':
        case 'CHANGE_DESTINATION_COUNTRY':
            if (!destinations[bank][method][country][state]) {
                return getAnyProperty(destinations[bank][method][country]);
            }
            return state;
        case 'CHANGE_DESTINATION_CURRENCY':
        case 'SET_CUSTOM_DESTINATION_CURRENCY':
            return action.currency;
        default:
            return state;
    }
};

const webAppEnabled = (state = defaultWebAppEnabled, action) => {
    switch (action.type) {
        case 'SET_WEB_APP_ENABLED':
            if (action.webAppEnabled !== state) return action.webAppEnabled;
            return state;
        default:
            return state;
    }
};

const remittanceReference = (state, action) => {
    switch (action.type) {
        case 'SET_REMITTANCE_REFERENCE':
            return action.value !== '' ? action.value?.trim() : undefined;
        default:
            return state;
    }
};

const advanced = (state = TOKEN_ENV === 'prd', action) => {
    switch (action.type) {
        case 'SET_ADVANCED_CONFIG':
            return true;
        default:
            return state;
    }
};

const betaVersion = (state = false, action) => {
    switch (action.type) {
        case 'SET_BETA_VERSION':
            return action.betaVersionEnabled;
        default:
            return state;
    }
};

const webappInIframe = (state = false, action) => {
    switch (action.type) {
        case 'SET_WEB_APP_IN_IFRAME':
            return action.webappInIframe;
        default:
            return state;
    }
};

const webappV2Modal = (state = false, action) => {
    switch (action.type) {
        case 'SET_WEB_APP_V2_MODAL':
            return action.webappV2Modal;
        default:
            return state;
    }
};

const cafEnabled = (state = false, action) => {
    switch (action.type) {
        case 'SET_CAF_ENABLED':
            return action.isCafEnabled;
        default:
            return state;
    }
};

const memberType = (state = TYPE_ONE, action) => {
    switch (action.type) {
        case 'SET_MEMBER_TYPE':
            return action.memberType;
        default:
            return state;
    }
};

const customDestinationReducer = (state = {account: {}}, action) => {
    // Custom destinations not supported
    if (!customDestination) {
        return state;
    }

    if (!state.scheme) {
        state = {...state, scheme: customDestination.supportedSchemes[0]};
    }

    if (!state.currency) {
        state = {...state, currency: supportedCurrencies(state.scheme)[0]};
    }

    if (!state.testPricesEnabled) {
        state = {...state, testPricesEnabled: false};
    }

    switch (action.type) {
        case 'SET_CUSTOM_DESTINATION_ACCOUNT':
            return {...state, account: action.account};
        case 'SET_CUSTOM_DESTINATION_SCHEME':
            if (!customDestination.supportedSchemes.includes(action.scheme)) {
                return state;
            }
            if (!supportedCurrencies(action.scheme).includes(state.currency)) {
                state = {...state, currency: supportedCurrencies(action.scheme)[0]};
            }
            return {...state, scheme: action.scheme};
        case 'SET_CUSTOM_DESTINATION_CURRENCY':
            if (!supportedCurrencies(state.scheme).includes(action.currency)) {
                return state;
            }
            return {...state, currency: action.currency};
        case 'SET_DEV_KEY':
            return {...state, devKey: action.devKey};
        case 'SET_TEST_PRICES':
            return {...state, testPricesEnabled: action.testPricesEnabled};
        default:
            return state;
    }
};

const sourceReducer =  (state = {account: {} }, action) => {
    if (!state.scheme) {
        state = {...state, scheme: customSource.supportedSourceSchemes[0]};
    }
    switch (action.type) {
        case 'SET_SOURCE_ACCOUNT':
            return {
                ...state,
                account: action.sourceAccount ? {
                    ...state.account,
                    ...action.sourceAccount,
                } : state.account,
                scheme: action.scheme !== state.scheme ? action.scheme : state.scheme,
                bankId: action.bankId !== state.bankId ? action.bankId : state.bankId,
            };
        case 'SET_SOURCE_COUNTRY':
            return {...state, sourceCountry: action.country !== '' ? action.country : undefined};
        case 'SET_SELECTED_BANK_INFO':
            return {...state, bankInfo: action?.bankInfo || null};
        case 'SET_CREDENTIALS':
            return {...state, credentials: action.credentials};
        default:
            return state;
    }
};

const configMenu = (state = {}, action) => {
    const w = webAppEnabled(state.webAppEnabled, action);
    const a = advanced(state.advanced, action);
    const b = bank(state.bank, action);
    const m = method(state.method, action, b);
    const co = country(state.country, action, b, m);
    const cu = currency(state.currency, action, b, m, co);
    const cd = customDestinationReducer(state.customDestination, action);
    const sa = sourceReducer(state.source, action);
    const bv = betaVersion(state.betaVersion, action);
    const ifrm = webappInIframe(state.webappInIframe, action);
    const wav2 = webappV2Modal(state.webappV2Modal, action);
    const type = memberType(state.memberType, action);
    const caf = cafEnabled(state.isCafEnabled, action);
    const rr = remittanceReference(state.remittanceReference, action);
    return {
        webAppEnabled: w,
        advanced: a,
        bank: b,
        method: m,
        country: co,
        currency: cu,
        customDestination: cd || {},
        source: sa,
        betaVersion: bv,
        memberType: type,
        isCafEnabled: caf,
        remittanceReference: rr,
        webappInIframe: ifrm,
        webappV2Modal: wav2,
    };
};

export default configMenu;
