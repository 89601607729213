import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import configMenuActions from 'actions/configMenuActions';
import { MerchantClient } from '../../util';
import { withHistory } from 'SimpleHistory';
import { connect } from 'react-redux';
import { formatUrl } from '../../util';
import {APP_SDK_ENV} from 'config/constants';

const TokenButtonV2 = ({
    amount,
    currency,
    destination,
    devKey,
    extra,
    option,
    source,
    webAppEnabled,
    bulkTransfers,
    sourceCountry,
    betaVersionEnabled,
    memberType,
    clientName,
    cafEnabled,
    remittanceReference,
    credentials,
    text,
    onError,
    setProviderDetails,
    history,
}) => {
    const modalControllerRef = useRef(null);

    let modalController = modalControllerRef.current;

    const handleSuccess = async data => {
        try {
            const res = await MerchantClient.instructPayment(data);
            if (res.data.providerDetails) {
                setProviderDetails(res.data.providerDetails);
            }
            const { status } = res.data;
            const url = formatUrl(clientName, status === 'SUFFICIENT_FUNDS' ? '/cart/fundsConfirmed' :
                status === 'INSUFFICIENT_FUNDS' ? '/cart/insufficientFunds' : '/cart/thankyou');
            history.push(url);
        } catch (error) {
            onError(error.response.data);
            history.push(formatUrl(clientName, '/cart/error'));
        }
    };

    const handleError = error => {
        console.error(error);
        modalController.cleanUp();
        onError(error);
        history.push(formatUrl(clientName, '/cart/error'));
    };

    const initializeModal = () => {
        const appSdk = new window.TokenApp({ env: APP_SDK_ENV });
        modalController = appSdk.buildController({
            onSuccess: handleSuccess,
            onFailure: handleError,
        });
    };

    const handleOnClick = async () => {
        const appModal = modalController.initApp();
        try {
            const { data: tokenRequestUrl } = await MerchantClient.getTokenRequestUrl(
                amount, currency, destination, devKey, extra, option, source,
                webAppEnabled, bulkTransfers, sourceCountry, betaVersionEnabled, memberType,
                clientName, cafEnabled, remittanceReference, credentials, true,
            );
            appModal.navigateToUrl(tokenRequestUrl);
        } catch (error) {
            // execute error callback
            if (error.response.data.message)
                error.message = error.response.data.message;
            else
                error.message = 'Error fetching Token Request URL';
            handleError(error);
        }
    };

    useEffect(() => {
        initializeModal();
    }, [amount, currency, destination, devKey, extra, option, source,
        webAppEnabled, bulkTransfers, sourceCountry, betaVersionEnabled, memberType,
        clientName, cafEnabled, remittanceReference, credentials, onError]);

    return (
        <div id="myTokenButton" className="TokenEnablerPlaceholder">
            <button className="CustomTokenButton" onClick={handleOnClick}>
                <span>{text || 'Pay By Bank'}</span>
            </button>
        </div>
    );
};

TokenButtonV2.propTypes = {
    amount: PropTypes.number.isRequired,
    currency: PropTypes.string.isRequired,
    destination: PropTypes.object.isRequired,
    devKey: PropTypes.string.isRequired,
    extra: PropTypes.object,
    option: PropTypes.object,
    source: PropTypes.object,
    webAppEnabled: PropTypes.bool,
    bulkTransfers: PropTypes.array,
    sourceCountry: PropTypes.string,
    betaVersionEnabled: PropTypes.bool,
    memberType: PropTypes.string,
    clientName: PropTypes.string.isRequired,
    cafEnabled: PropTypes.bool,
    remittanceReference: PropTypes.string,
    credentials: PropTypes.object.isRequired,
    text: PropTypes.string,
    onError: PropTypes.func.isRequired,
    setProviderDetails: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
};

TokenButtonV2.defaultProps = {
    text: 'Enable Token',
    extra: {},
    option: {},
    source: {},
    webAppEnabled: false,
    bulkTransfers: [],
    sourceCountry: '',
    betaVersionEnabled: false,
    memberType: '',
    cafEnabled: false,
    remittanceReference: '',
};

const mapStateToProps = state => ({
    clientName: state.clientName,
});

const mapDispatchToProps = {
    setProviderDetails: configMenuActions.setProviderDetails,
};

export default withHistory(connect(mapStateToProps, mapDispatchToProps)(TokenButtonV2));
