module.exports = {
    TYPE_ONE: 'type1',
    PATH_VRP_CONSENT: '/vrp-consents',
    PATH_BANK_AUTH_CALLBACK: '/banks/{BANK_ID}/callback',
    PATH_VRPS: '/vrps',
    PATH_REVOKE_VRP: '/vrp-consents/{VRP_CONSENT_ID}',
    VRP_TYPE_SWEEPING: 'SWEEPING',
    VRP_TYPE_NON_SWEEPING: 'NON_SWEEPING',
    VRP_CONSENT_STATUS: {
        INVALID_STATUS: 'INVALID_STATUS',
        PENDING: 'PENDING',
        PENDING_MORE_INFO: 'PENDING_MORE_INFO',
        PENDING_REDIRECT_AUTH: 'PENDING_REDIRECT_AUTH',
        PENDING_REDIRECT_AUTH_VERIFICATION: 'PENDING_REDIRECT_AUTH_VERIFICATION',
        AUTHORIZED: 'AUTHORIZED',
        REJECTED: 'REJECTED',
        REVOKED: 'REVOKED',
        FAILED: 'FAILED',
    },
};
