import cartActions from 'actions/cartActions';

const cartItem = ( state = {}, action ) => {
    switch (action.type) {
        case 'ADD_TO_CART':
            if (state.id === action.id) {
                return {
                    ...state,
                    qty: state.qty + action.qty,
                };
            }
            return state;
        case 'REMOVE_FROM_CART':
            if (state.id === action.id) {
                if ( state.qty > action.qty ) {
                    return {
                        ...state,
                        qty: state.qty - action.qty,
                    };
                } else {
                    return;
                }
            }
            return state;
        case 'SET_CART_QUANTITY':
            if (state.id === action.id && state.qty !== action.qty) {
                if ( action.qty > 0 ) {
                    return {
                        ...state,
                        qty: action.qty,
                    };
                } else {
                    return;
                }
            }
            return state;
        default:
            return state;
    }
};

const cart = ( state = [], action ) => {
    switch (action.type) {
        case 'INSERT_TO_CART':
            if (state.find(item => (item.id === action.id))) {
                return state.map(i =>
                    cartItem( i, cartActions.add(action.id) ),
                );
            }
            return [
                ...state,
                {
                    id: action.id,
                    thumb: action.thumb,
                    price: action.price,
                    testPrice: action.testPrice,
                    title: action.title,
                    qty: 1,
                },
            ];
        case 'ADD_TO_CART':
            return state.map(item => cartItem(item, action));
        case 'SET_CART_QUANTITY':
            return state.map(item => cartItem(item, action));
        case 'SET_CART_QUANTITIES':
            return state.reduce( ( newCart, item ) => {
                const qty = action.qtys[item.id];
                if (!qty) return [...newCart, item];
                const newQty = parseInt(action.qtys[item.id]);
                if (newQty <= 0) return newCart;
                return [
                    ...newCart,
                    cartItem(item, cartActions.setQuantity(item.id, newQty)),
                ];
            }, []);
        case 'CLEAN_CART':
            return [];
        case '@@router/LOCATION_CHANGE':
            return (action.payload.pathname === '/cart/thankyou' ? [] : state);
        default:
            return state;
    }
};

export default cart;
