const cart = {
    insert: ( item, qty = 1 ) =>
        ({
            type: 'INSERT_TO_CART',
            ...item,
            qty: parseInt(qty),
        }),
    add: ( id, qty = 1 ) =>
        ({
            type: 'ADD_TO_CART',
            id,
            qty: parseInt(qty),
        }),
    remove: ( id, qty = 1) =>
        ({
            type: 'REMOVE_FROM_CART',
            id,
            qty: parseInt(qty),
        }),
    setQuantity: ( id, qty ) =>
        ({
            type: 'SET_CART_QUANTITY',
            id,
            qty: parseInt(qty),
        }),
    setQuantities: qtys =>
        ({
            type: 'SET_CART_QUANTITIES',
            qtys,
        }),
    cleanCart: () =>
        ({
            type: 'CLEAN_CART',
        }),
    setTransferError: error =>
        ({
            type: 'TRANSFER_ERROR',
            errorMessage: error,
        }),
};

export default cart;
