import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {withHistory} from 'SimpleHistory';
import {connect} from 'react-redux';
import TokenButton from './index.js';
import axios from 'axios';
import {formatUrl} from '../../util';
import { memberInfo } from 'config/constants.js';
import base64url from 'base64url';
import DebounceRender from '../../DebounceRender.js';
import VRPSetupFormDefaults from '../VRPSetupFormDefaults';

const merchantInstance = axios.create({
    baseURL: window.location.origin,
});

const ButtonVRP = props => {
    const {
        onError,
        data,
        text,
        devKey,
        history,
        isReady,
        memberId,
        memberType,
        clientName,
        webAppEnabled,
        readyToBounce,
        remittanceInformation,
        storeVRPSetupFormValues,
    } = props;

    const [redirectFunc, setRedirectFunc] = useState(() => () => {});
    const [popupFuncAsync, setPopupFuncAsync] = useState(() => () => {});
    const [onSuccessAsync, setOnSuccessFuncAsync] = useState(() => () => {});
    const [onFailure, setOnFailureFunc] = useState(() => () => {});

    useEffect(() => {
        const reqBody = {
            ...data,
            memberType,
            clientName,
            memberId,
            webAppEnabled,
            remittanceInformation,
            devKey,
        };

        setRedirectFunc(() => redirect => {
            storeVRPSetupFormValues(reqBody);
            redirect('/vrp-consent', {
                ...reqBody,
            });
        });

        setPopupFuncAsync(() => async(done, errorCb) => {
            try {
                storeVRPSetupFormValues(reqBody);
                const {data: tokenRequestUrl} = await merchantInstance({
                    method: 'post',
                    url: '/vrp-consent',
                    data: reqBody,
                });
                done(tokenRequestUrl);
            } catch (error) {
                // execute error callback
                if (error.response.data.message)
                    error.message = error.response.data.message;
                else
                    error.message = 'Error fetching Token Request URL';
                errorCb(error);
            }
        });

        setOnSuccessFuncAsync(() => async data => {
            try {
                const res = await merchantInstance({
                    method: 'post',
                    url: '/vrp/gateway',
                    data,
                });
                const tppRedirectUrl = new URL(res.data.initiation.callbackUrl);
                const redirectState = tppRedirectUrl.searchParams.get('redirect-state');
                const encData = base64url.toBase64(base64url.encode(JSON.stringify(res.data)));
                history.push(formatUrl(clientName, `/services/vrp/setup/success?data=${encData}&redirect-state=${redirectState}`));
            } catch (error) {
                onError(error.response.data);
                // error screen
                history.push(formatUrl(clientName, '/services/vrp/setup/error'));
            }
        });

        setOnFailureFunc(() => error => { // Failure Callback
            history.push(formatUrl(clientName, '/services/vrp/setup/error'));
            onError(error);
            throw error;
        });

    }, [isReady, memberType, clientName, data]);

    return readyToBounce && (
        <TokenButton
            redirectFunc={redirectFunc}
            popupFuncAsync={popupFuncAsync}
            onSuccessAsync={onSuccessAsync}
            onFailure={onFailure}
            isReady={isReady}
            text={text}
            data={data}
            webAppEnabled={webAppEnabled}
        />
    );
};

ButtonVRP.propTypes = {
    text: PropTypes.string,
    clientName: PropTypes.string,
    webAppEnabled: PropTypes.bool,
    isReady: PropTypes.bool,
    data: PropTypes.object,
    history: PropTypes.object,
    memberType: PropTypes.string,
    memberId: PropTypes.string,
    onError: PropTypes.func,
    devKey: PropTypes.string,
    readyToBounce: PropTypes.bool,
    storeVRPSetupFormValues: PropTypes.func,
    remittanceInformation: PropTypes.string,
};

ButtonVRP.defaultProps = {
    text: 'Setup Now',
    onError: () => {},
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;

    return {
        webAppEnabled: state.configMenu.webAppEnabled,
        memberType,
        clientName,
        memberId: memberInfo[clientName][memberType]['id'],
        remittanceInformation: state.configMenu.remittanceReference,
        devKey: state.configMenu.customDestination.devKey,
    };
};

const TokenButtonVRP = props => {
    const vrpType = props.data.vrpType;
    props = {...props, vrpType};
    return (
        <VRPSetupFormDefaults {...props}>
            <DebounceRender>
                <ButtonVRP />
            </DebounceRender>
        </VRPSetupFormDefaults>
    );
};

TokenButtonVRP.propTypes = {
    data: PropTypes.object,
};

const areEqual = (prevProps, nextProps) => {
    try {
        if (
            JSON.stringify(nextProps) !== JSON.stringify(prevProps)
        ) {
            return false;
        }
        return true;
    } catch (e) {
        return false;
    }
};

export default withHistory(connect(mapStateToProps)(React.memo(TokenButtonVRP, areEqual)));
