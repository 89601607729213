import React from 'react';
import NoDataIconPng from 'assets/no-data.png';

const NoDataIcon = props => {

    return (
        <img src={NoDataIconPng} alt='' {...props} />
    );
};

export default NoDataIcon;
