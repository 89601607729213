import React from 'react';
import PayIconPng from 'assets/payment.png';

const PayIcon = props => {

    return (
        <img src={PayIconPng} alt='' {...props} />
    );
};

export default PayIcon;
