import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from 'SimpleHistory';
import {connect} from 'react-redux';
import { memberInfo, VRP_TYPE_NON_SWEEPING } from 'config/constants';
import './SubscriptionList.css';
import DeleteIcon from '../../../Icons/DeleteIcon';
import PayIcon from '../../../Icons/PayIcon';
import HistoryIcon from '../../../Icons/HistoryIcon';
import vrpActions from 'actions/vrp.js';
import ConfirmationModal from '../ConfirmationModal';
import axios from 'axios';

const merchantInstance = axios.create({
    baseURL: window.location.origin,
});

const SubscriptionList = ({ vrpConsentList, memberId, deleteVRPConsent }) => {
    const [subscriptionList, setSubscriptionList] = useState([]);
    const [isModalOpen, setModalOpen] = useState(false);
    const [deleteId, setDeleteId] = useState('');

    const setConfirmationModalOpen = vrpId => {
        setDeleteId(vrpId);
        setModalOpen(true);
    };

    const revokeConsent = useCallback(async vrpId => {
        try {
            const resp = await merchantInstance({
                method: 'delete',
                url: '/vrp-consent',
                data: {
                    vrpId,
                    memberId,
                },
            });
            if (resp.status === 200) {
                if (resp.data.status === 'REVOKED') {
                    await deleteVRPConsent(vrpId, VRP_TYPE_NON_SWEEPING);
                    return true;
                }
            }
            return false;
        } catch (e) {
            return false;
        }
    }, [memberId]);

    useEffect(
        () => {
            if (vrpConsentList) {
                setSubscriptionList(Object.keys(vrpConsentList).map(k => { return { id: k, ...vrpConsentList[k] }; }));
            } else {
                setSubscriptionList([]);
            }
        },
        [JSON.stringify(vrpConsentList), memberId],
    );

    if (subscriptionList && subscriptionList.length > 0) {
        return (
            <div className='Subscription-list'>
                <div className='Subscription-list-title'>
                    {'Subscribers'}
                </div>
                <table>
                    <thead>
                        <tr key='header'>
                            <th>{'S. No.'}</th>
                            <th>{'Service Name'}</th>
                            <th>{'Setup Id'}</th>
                            <th>{'Start Date'}</th>
                            <th>{'End Date'}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            subscriptionList.map((vrp, i) => {
                                return (
                                    <tr key={vrp.refId} className='Subscription-list-row'>
                                        <td className={`subscription-s-no_${i + 1}`}>{(i + 1) + '.'}</td>
                                        <td className={`subscription-acc-nickname_${i + 1}`}>{vrp.accountNickName}</td>
                                        <td className={`subscription-ref-id_${i + 1}`}>{vrp.refId}</td>
                                        <td>{new Date(vrp.startDateTime)?.toUTCString().slice(0,16)}</td>
                                        <td>{new Date(vrp.endDateTime)?.toUTCString().slice(0,16)}</td>
                                        <td className={`center subscription-revoke_${i + 1}`}>
                                            {
                                                <DeleteIcon style={{cursor: 'pointer'}} onClick={() => setConfirmationModalOpen(vrp.id)} />
                                            }
                                        </td>
                                        <td className={`center subscription-adhoc-pay_${i + 1}`}>
                                            {
                                                <Link to={`/services/adhocpay?consent-id=${vrp.id}&vrp-type=${VRP_TYPE_NON_SWEEPING}`}>
                                                    <PayIcon />
                                                </Link>
                                            }
                                        </td>
                                        <td className={`center subscription-history_${i + 1}`}>
                                            {
                                                <Link to={`/services/payhistory?consent-id=${vrp.id}&vrp-type=${VRP_TYPE_NON_SWEEPING}`}>
                                                    <HistoryIcon />
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
                {
                    isModalOpen
                        && (
                            <ConfirmationModal
                                handleOverlay={setModalOpen}
                                handleConfirm={() => revokeConsent(deleteId)}
                            />
                        )
                }
            </div>
        );
    }
    return <></>;
};

SubscriptionList.propTypes = {
    vrpConsentList: PropTypes.object,
    memberId: PropTypes.string,
    deleteVRPConsent: PropTypes.func,
    cleanUpAutoVRPSetups: PropTypes.func,
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;
    const memberId = memberInfo[clientName][memberType]['id'];

    return {
        vrpConsentList: state.vrp?.[VRP_TYPE_NON_SWEEPING]?.vrpConsentList?.[memberId] || {},
        memberId,
    };
};

const mapDispatchToProps = {
    cleanUpAutoVRPSetups: vrpActions.cleanUpAutoVRPSetups,
    deleteVRPConsent: vrpActions.deleteVRPConsent,
};

export default showIfLocation(connect(mapStateToProps, mapDispatchToProps)(SubscriptionList));
