import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {withHistory} from 'SimpleHistory';
import {connect} from 'react-redux';
import axios from 'axios';
import {formatUrl} from '../../util';
import { VRP_PAYMENT_STATUS } from 'config/constants.js';
import vrpActions from 'actions/vrp.js';

const merchantInstance = axios.create({
    baseURL: window.location.origin,
});

const TokenButtonAdhoc = props => {
    const {
        data,
        text,
        isReady,
        history,
        setVrp,
        onError,
        clientName,
    } = props;

    const [onClickFuncAsync, setOnClickFuncAsync] = useState(() => () => {});
    const [disableButton, setDisableButton] = useState(false);

    useEffect(() => {
        const handleFailure = e => {
            // error screen
            setDisableButton(false);
            onError(e);
            history.push(formatUrl(clientName, '/services/adhocpay/error'));
        };

        setOnClickFuncAsync(() => async () => {
            try {
                setDisableButton(true);
                const res = await merchantInstance(
                    {
                        method: 'post',
                        url: '/vrp',
                        data,
                    },
                );
                setDisableButton(false);
                if (res.status === 200) {
                    if (
                        res.data.status === VRP_PAYMENT_STATUS.INITIATION_PROCESSING
                            || res.data.status === VRP_PAYMENT_STATUS.INITIATION_COMPLETED
                    ) {
                        await setVrp({
                            consentId: data.consentId,
                            memberId: data.memberId,
                            vrp: res.data,
                            vrpType: data.vrpType,
                        });
                        history.push(formatUrl(clientName, '/services/adhocpay/success'));
                    } else {
                        handleFailure();
                    }
                } else {
                    handleFailure();
                }
            } catch (e) {
                handleFailure(e.response.data);
            }
        });

    }, [clientName, data]);

    return (
        <button
            className='AdhocPayForm-pay-button'
            onClick={onClickFuncAsync}
            disabled={!isReady || disableButton}
        >
            {text}
        </button>
    );
};

TokenButtonAdhoc.propTypes = {
    text: PropTypes.string,
    isReady: PropTypes.bool,
    data: PropTypes.object,
    onError: PropTypes.func,
    setVrp: PropTypes.func,
    history: PropTypes.object,
    clientName: PropTypes.string,
};

TokenButtonAdhoc.defaultProps = {
    text: 'Pay',
    onError: () => {},
};

const mapStateToProps = state => {
    const clientName = state.clientName;
    return {
        clientName,
    };
};

const mapDispatchToProps = {
    setVrp: vrpActions.setVrp,
};

export default withHistory(connect(mapStateToProps, mapDispatchToProps)(TokenButtonAdhoc));
