import React, { useEffect, useState } from 'react';
import {IntlProvider} from 'react-intl';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import CreditCard from './Sweeping/CreditCard';
import AutoPaySetupList from './Sweeping/AutoPaySetupList';
import SubscriptionList from './NonSweeping/SubscriptionList';
import { memberInfo, VRP_TYPE_SWEEPING, VRP_TYPE_NON_SWEEPING } from 'config/constants';
import Footer from '../Footer';
import {showIfLocation, Link} from 'SimpleHistory';
import './Services.css';
import Subscription from './NonSweeping/Subscription';

const Services = ({ vrpConsentList, memberId, vrpSubscriptionList}) => {
    const [autoSetupAvail, setAutoSetupAvail] = useState(false);
    const [subscriptionAvail, setSubscriptionAvail] = useState(false);

    useEffect(
        () => {
            if (
                vrpConsentList
                && Object.keys(vrpConsentList)?.length > 0
            ) {
                setAutoSetupAvail(true);
            } else {
                setAutoSetupAvail(false);
            }
        },
        [JSON.stringify(vrpConsentList), memberId],
    );

    useEffect(
        () => {
            if (
                vrpSubscriptionList
                && Object.keys(vrpSubscriptionList)?.length > 0
            ) {
                setSubscriptionAvail(true);
            } else {
                setSubscriptionAvail(false);
            }
        },
        [JSON.stringify(vrpSubscriptionList), memberId],
    );

    return (
        <IntlProvider locale="en">
            <div className="Cart">
                <nav className="Breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/services" className="active">Services</Link>
                </nav>
                <div className="Services">
                    <div className="Services-container">
                        <CreditCard buttonText={autoSetupAvail ? 'Setup Another Card' : 'Setup Now'} />
                        <AutoPaySetupList />
                    </div>
                    <div className="Services-container">
                        <Subscription buttonText={subscriptionAvail ? 'Add Subscription' : 'Setup Now'} />
                        <SubscriptionList />
                    </div>
                </div>
                <Footer />
            </div>
        </IntlProvider>
    );
};

Services.propTypes = {
    vrpConsentList: PropTypes.object,
    vrpSubscriptionList: PropTypes.object,
    memberId: PropTypes.string,
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;
    const memberId = memberInfo[clientName][memberType]['id'];

    return {
        vrpConsentList: state.vrp?.[VRP_TYPE_SWEEPING]?.vrpConsentList?.[memberId] || {},
        vrpSubscriptionList: state.vrp?.[VRP_TYPE_NON_SWEEPING]?.vrpConsentList?.[memberId] || {},
        memberId,
    };
};

export default showIfLocation(connect(mapStateToProps)(Services));
