import React, {Component} from 'react';
import PropTypes from 'prop-types';
import TextInput from './TextInput';

class Credentials extends Component {
    render() {
        const {
            setCredentials,
            credentialsValue,
            requiredCredentials,
            sourceScheme,
            onKeyDown,
        } = this.props;

        return (
            <>
                { requiredCredentials &&
                    <span id="Extra-param-span">Credential fields</span>}
                <br />
                {requiredCredentials?.map(data => {
                    return (
                        data.id.toUpperCase() !== 'IBAN' || sourceScheme !== 'iban' ?
                            <TextInput
                                onKeyDown={e => onKeyDown(e)}
                                value={credentialsValue && credentialsValue[data.id] || ''}
                                key={'Credentials-' + data.id}
                                id= {'Credentials-' + data.id}
                                onChange={e => setCredentials({ [data.id]: e.target.value })}
                                placeholder={data.displayName}
                                required />
                            : null
                    );
                })}
            </>
        );
    }
}

Credentials.propTypes = {
    setCredentials: PropTypes.func,
    credentialsValue: PropTypes.object,
    requiredCredentials: PropTypes.array,
    sourceScheme: PropTypes.string,
    onKeyDown: PropTypes.func,
};

Credentials.defaultProps = {
    setCredentials: () => {},
    credentialsValue: {},
    requiredCredentials: [],
    onKeyDown: () => {},
};

export default Credentials;
