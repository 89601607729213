import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import {MerchantClient} from 'util.js';
import {withHistory} from 'SimpleHistory';
import {WEB_APP_ENV} from 'config/constants';
import {connect} from 'react-redux';
import {CLIENTS_URL_MAPPINGS} from '../../config/constants';
import {formatUrl} from '../../util';
import configMenuActions from 'actions/configMenuActions';

const SCRIPT_INTERVAL = 30;

class TokenButtonV1 extends React.Component {
    constructor(props) {
        super(props);
        this.addPayButton = this.addPayButton.bind(this);
        this.clientBaseUrl = CLIENTS_URL_MAPPINGS[this.props.clientName];
    }

    componentDidMount() {
        this.addPayButton(
            this.props.amount, this.props.currency, this.props.destination,
            this.props.devKey, this.props.extra, this.props.option, this.props.source,
            this.props.webAppEnabled, this.props.bulkTransfers, this.props.sourceCountry,
            this.props.betaVersionEnabled, this.props.memberType, this.props.clientName,
            this.props.cafEnabled, this.props.remittanceReference, this.props.credentials,
            this.props.webappInIframe);
    }

    componentDidUpdate(prevProps) {
        !this.state?.webappIframeEnabled
        && (JSON.stringify(prevProps) !== JSON.stringify(this.props))
        && this.addPayButton(this.props.amount, this.props.currency, this.props.destination,
            this.props.devKey, this.props.extra, this.props.option, this.props.source,
            this.props.webAppEnabled, this.props.bulkTransfers, this.props.sourceCountry,
            this.props.betaVersionEnabled, this.props.memberType,this.props.clientName,
            this.props.cafEnabled, this.props.remittanceReference, this.props.credentials,
            this.props.webappInIframe);
    }

    // Retries adding the pay button until window.Token is defined (token.js loaded)
    async addPayButton(amount, currency, destination, devKey, extra, option,
        source, webAppEnabled, bulkTransfers, sourceCountry,
        betaVersionEnabled, memberType, clientName, cafEnabled, remittanceReference, credentials,
        webappInIframe)
    {
        const {onError, text, isReady, setProviderDetails} = this.props;
        if (!window.Token) {
            // retry until Token.js is available
            setTimeout(
                this.addPayButton,
                SCRIPT_INTERVAL,
                amount,
                currency,
                destination,
                devKey,
                extra,
                option,
                source,
                webAppEnabled,
                bulkTransfers,
                sourceCountry,
                betaVersionEnabled,
                memberType,
                clientName,
                cafEnabled,
                remittanceReference,
                credentials,
                webappInIframe,
            );
            return;
        }

        const token = new window.Token({
            env: WEB_APP_ENV,
        });

        // destroy previous button instance
        if (this.button) {
            this.button.destroy();
        }

        const webAppIframeEl = window.document.getElementById('tokenWebAppIframe');

        // create button instance
        this.button = token.createTokenButton(this.buttonRef, {
            label: text,
            hideLogo: clientName === 'southside' ? true : false,
        });

        const webAppIframe = () => {
            this.setState({webappIframeEnabled: true});
            return token.createTokenWebAppIframe(webAppIframeEl, {
                height: '610px',
                width: '500px',
            });
        };

        this.tokenController = token.createController({
            onSuccess: async data => { // Success Callback
                try {
                    const res = await MerchantClient.instructPayment(data);
                    res.data.providerDetails && setProviderDetails(res.data.providerDetails);
                    res.data.status === 'SUFFICIENT_FUNDS' ?
                        this.props.history.push(formatUrl(clientName, '/cart/fundsConfirmed'))
                        : res.data.status === 'INSUFFICIENT_FUNDS' ?
                            this.props.history.push(formatUrl(clientName, '/cart/insufficientFunds'))
                            : this.props.history.push(formatUrl(clientName, '/cart/thankyou'));

                } catch (error) {
                    onError(error.response.data);
                    // error screen
                    this.props.history.push(formatUrl(clientName, '/cart/error'));
                }
            },
            onError: error => { // Failure Callback
                console.log(error);
                onError(error);
                this.props.history.push(formatUrl(clientName, '/cart/error'));
                throw error;
            },
        });

        // bind the Token Button to the Token Controller when ready
        this.tokenController.bindButtonClick(
            this.button, // Token Button
            token.createRequest(
                redirect => { // redirect
                    redirect('/getTokenRequestUrl', {
                        amount,
                        currency,
                        destination,
                        devKey,
                        extra,
                        option,
                        source,
                        webAppEnabled,
                        bulkTransfers,
                        sourceCountry,
                        betaVersionEnabled,
                        memberType,
                        clientName,
                        cafEnabled,
                        remittanceReference,
                        credentials,
                    });
                },
                async (done, errorCb) => { // optional async request for popup
                    try {
                        const {data: tokenRequestUrl} = await MerchantClient.getTokenRequestUrl(
                            amount, currency, destination, devKey, extra, option, source,
                            webAppEnabled, bulkTransfers, sourceCountry, betaVersionEnabled, memberType,
                            clientName, cafEnabled, remittanceReference, credentials,
                        );
                        done(tokenRequestUrl);
                    } catch (error) {
                        // execute error callback
                        if (error.response.data.message)
                            error.message = error.response.data.message;
                        else
                            error.message = 'Error fetching Token Request URL';
                        errorCb(error);
                    }
                },
            ),
            async error => { // bindComplete callback
                if (error) throw error;
                await MerchantClient.getMerchantUsername();
                // enable button after binding
                isReady && this.button.enable();
            },
            { // options
                desktop: webAppEnabled && (webappInIframe ? 'IFRAME' : 'POPUP') || 'REDIRECT',
                webAppIframe,
            },
        );
    }

    render() {
        return (
            <div id='myTokenButton' className="TokenEnablerPlaceholder" ref={ref => {
                this.buttonRef = ref;
            }}>
            </div>
        );
    }
}

TokenButtonV1.propTypes = {
    onClick: PropTypes.func,
    onError: PropTypes.func,
    text: PropTypes.string,
    amount: PropTypes.number,
    currency: PropTypes.string,
    webAppEnabled: PropTypes.bool,
    destination: PropTypes.object,
    devKey: PropTypes.string,
    isReady: PropTypes.bool,
    history: PropTypes.object,
    extra: PropTypes.object,
    option: PropTypes.object,
    source: PropTypes.object,
    bulkTransfers: PropTypes.array,
    sourceCountry: PropTypes.string,
    betaVersionEnabled: PropTypes.bool,
    memberType: PropTypes.string,
    url: PropTypes.string,
    clientName: PropTypes.string,
    cafEnabled: PropTypes.bool,
    credentials: PropTypes.object,
    setProviderDetails: PropTypes.func,
    remittanceReference: PropTypes.string,
    webappInIframe: PropTypes.bool,
};

TokenButtonV1.defaultProps = {
    onClick: () => {
    },
    onError: () => {
    },
    text: 'Enable Token',
};

const mapStateToProps = state => {
    return {
        clientName: state.clientName,
    };
};

const mapDispatchToProps = {
    setProviderDetails: configMenuActions.setProviderDetails,
};

export default withHistory(connect(mapStateToProps,mapDispatchToProps)(TokenButtonV1));
