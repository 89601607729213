import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {withHistory} from 'SimpleHistory';
import {WEB_APP_ENV} from 'config/constants';

const SCRIPT_INTERVAL = 30;

let globalButton;

const TokenButton = props => {
    const {
        text,
        isReady,
        onSuccessAsync,
        onFailure,
        redirectFunc,
        popupFuncAsync,
        webAppEnabled,
        webappInIframe,
    } = props;

    let buttonRef;

    const addPayButton = async () => {
        if (!window.Token) {
            // retry until Token.js is available
            setTimeout(
                addPayButton,
                SCRIPT_INTERVAL,
            );
            return;
        }

        const token = new window.Token({
            env: WEB_APP_ENV,
        });

        // destroy previous button instance
        if (globalButton) {
            globalButton.destroy();
        }

        const webAppIframeEl = window.document.getElementById('tokenWebAppIframe');

        // create button instance
        globalButton = token.createTokenButton(buttonRef, {
            label: text,
            hideLogo: true,
        });

        const webAppIframe = () => {
            return token.createTokenWebAppIframe(webAppIframeEl, {
                height: '610px',
                width: '500px',
            });
        };

        const tokenController = token.createController(
            {
                onSuccess: onSuccessAsync,
                onError: onFailure,
            },
        );

        // bind the Token Button to the Token Controller when ready
        tokenController.bindButtonClick(
            globalButton, // Token Button
            token.createRequest(redirectFunc, popupFuncAsync),
            async error => {
                if (error) throw error;
                isReady && globalButton.enable();
            },
            { // options
                desktop: webAppEnabled && (webappInIframe ? 'IFRAME' : 'POPUP') || 'REDIRECT',
                webAppIframe,
            },
        );
    };

    useEffect(() => {
        addPayButton();
    },[]);

    return (
        <div id='myTokenButton' className="TokenEnablerPlaceholder" ref={ref => {
            buttonRef = ref;
        }}>
        </div>
    );
};

TokenButton.propTypes = {
    text: PropTypes.string,
    webAppEnabled: PropTypes.bool,
    webappInIframe: PropTypes.bool,
    redirectFunc: PropTypes.func,
    popupFuncAsync: PropTypes.func,
    onSuccessAsync: PropTypes.func,
    onFailure: PropTypes.func,
    isReady: PropTypes.bool,
};

TokenButton.defaultProps = {
    text: 'Enable Token',
    redirectFunc: () => {},
    popupFuncAsync: async () => {},
    onSuccessAsync: async () => {},
    onFailure: () => {},
};

export default withHistory(TokenButton);
