import React from 'react';
import DeleteIconPng from 'assets/delete.png';

const DeleteIcon = props => {

    return (
        <img src={DeleteIconPng} alt='' {...props} />
    );
};

export default DeleteIcon;
