import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {showIfLocation, Link} from 'SimpleHistory';
import {connect} from 'react-redux';
import './CreditCard.css';

class CreditCard extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const buttonText = this.props.buttonText || 'Setup Now';

        return (
            <IntlProvider locale="en">
                <div className='CreditCard'>
                    <div className='CreditCard-body'>
                        <div className='CreditCard-header-container'>
                            <div className='CreditCard-header'>
                                Credit Card Payments
                            </div>
                        </div>
                        <div className='CreditCard-content'>
                            <div>Token supports the ability to pay your credit card using open banking variable recurring payments securely in an easy and efficient way.</div>
                        </div>
                        <div className='CreditCard-button-container'>
                            <Link
                                to="/services/sweeping/autopay/setup"
                                className="CreditCard-button">
                                {buttonText}
                            </Link>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

CreditCard.propTypes = {
    buttonText: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    return {
        buttonText: ownProps.buttonText,
    };
};

export default showIfLocation(connect(mapStateToProps)(CreditCard));
