import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {showIfLocation, Link} from 'SimpleHistory';
import {getSubtotal} from 'util.js';
import {FormattedNumber, FormattedMessage} from 'react-intl';
import './StandardCheckout.css';
import {currencySymbols} from 'config/constants';
import {getCurrency} from 'reducers';
import 'assets/dropdown1.png';
import cardsIcons from 'assets/cards-icons.png';
import cvvCode from 'assets/cvv-code.png';

const StandardCheckout = ({
    cart,
    currency,
}) => {
    const subtotal = getSubtotal(cart);
    const tax = 0;
    const shipping = 0;
    return (
        <div className="StandardCheckout">
            <nav className="Breadcrumbs">
                <Link to="/">Home</Link>
                <Link to="/cart">Cart</Link>
                <Link to="/cart/checkout" className="active">Checkout</Link>
            </nav>
            <div className="StandardCheckout-container">
                <div className="StandardCheckout-content">
                    <section className="PanelCheckout">
                        <div className="PanelCheckout-row">
                            <div className="col-2">
                                <h2>
                                    Payment Method
                                </h2>
                                <small>
                                    All major credit cards accepted.
                                </small>
                            </div>
                            <div className="col-2 text-right
                                StandardCheckout-cards">
                                <img
                                    src={cardsIcons}
                                    alt="Discover, Visa, Mastercard, AMEX, JCB"
                                    width="119"
                                    height="50"
                                />
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Cardholder Name (exactly as shown on card)
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Card Number
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-Expiration">
                                <span className="StandardCheckout-label">
                                    Expiration Date
                                </span>
                                <span className="StandardCheckout-control">
                                    <select defaultValue="-">
                                        <option value="-" data-default>
                                            Month
                                        </option>
                                        <option>January</option>
                                        <option>February</option>
                                        <option>March</option>
                                        <option>April</option>
                                        <option>May</option>
                                        <option>June</option>
                                        <option>July</option>
                                        <option>August</option>
                                        <option>September</option>
                                        <option>October</option>
                                        <option>November</option>
                                        <option>December</option>
                                    </select>
                                    <select defaultValue="-">
                                        <option value="-" data-default>
                                            Year
                                        </option>
                                        <option>2017</option>
                                        <option>2018</option>
                                        <option>2019</option>
                                        <option>2020</option>
                                        <option>2021</option>
                                        <option>2022</option>
                                    </select>
                                </span>
                            </div>
                            <div className="StandardCheckout-SecurityCode">
                                <span className="StandardCheckout-label">
                                    Security Code (CCV2)
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                    <img
                                        src={cvvCode}
                                        alt="CVV code"
                                    />
                                </span>
                            </div>
                        </div>
                    </section>

                    <section className="PanelCheckout StandardCheckout-BillingAddress">
                        <div className="PanelCheckout-row">
                            <div className="flex-column flex-item">
                                <h2>
                                    Billing Address
                                </h2>
                                <small>
                                    Get started by filling out the form bellow.
                                </small>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    First Name:
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Last Name:
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Address
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Address 2 (optional: apartments, suite, floor, etc.)
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    City
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    State/Province/Region
                                </span>
                                <span className="StandardCheckout-control">
                                    <select>
                                        <option>CALIFORNIA</option>
                                    </select>
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Zip/Postal Code
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-Phone col-3 flex-item-gutter">
                                <span className="StandardCheckout-label">
                                    Phone
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                    </section>

                    <div className="StandardCheckout-SameCheckbox">
                        <span className="SameCheckbox-Control">
                            <input type="checkbox" />
                        </span>
                        <span className="SameCheckbox-Label">
                            Same as the billing address
                        </span>
                    </div>

                    <section className="PanelCheckout">
                        <div className="PanelCheckout-row">
                            <div className="flex-column flex-item">
                                <h2>
                                    Shipping Address
                                </h2>
                                <small>
                                    Get started by filling out the form bellow.
                                </small>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    First Name:
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Last Name:
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Address
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Address 2 (optional: apartments, suite, floor, etc.)
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    City
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    State/Province/Region
                                </span>
                                <span className="StandardCheckout-control">
                                    <select>
                                        <option>CALIFORNIA</option>
                                    </select>
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    Zip/Postal Code
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                        <div className="StandardCheckout-row">
                            <div className="StandardCheckout-Phone col-3 flex-item-gutter">
                                <span className="StandardCheckout-label">
                                    Phone
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                            <div className="StandardCheckout-item">
                                <span className="StandardCheckout-label">
                                    E-mail:
                                </span>
                                <span className="StandardCheckout-control">
                                    <input />
                                </span>
                            </div>
                        </div>
                    </section>
                </div>
                <aside className="StandardCheckout-summary">
                    <div className="Panel">
                        <dl className="Totals-subTotal">
                            <dt>Subtotal</dt>
                            <dd>{currencySymbols[currency]}
                                <FormattedNumber
                                    value={subtotal}
                                    minimumFractionDigits={2}
                                    maximumFractionDigits={2}
                                />
                            </dd>
                        </dl>
                        <dl className="Totals-subTotal">
                            <dt>Tax</dt>
                            <dd>
                                <FormattedMessage
                                    id="tax"
                                    defaultMessage={`{tax, plural,
                                        =0 {----}
                                        other {€ {formattedTax}}
                                    }`}
                                    values={{
                                        tax,
                                        formattedTax: (
                                            <FormattedNumber
                                                value={tax}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />),
                                    }}
                                />
                            </dd>
                        </dl>
                        <dl className="Totals-subTotal">
                            <dt>Shipping</dt>
                            <dd>
                                <FormattedMessage
                                    id="shipping"
                                    defaultMessage={`{shipping, plural,
                                        =0 {----}
                                        other {€ {formattedShipping}}
                                    }`}
                                    values={{
                                        shipping,
                                        formattedShipping: (
                                            <FormattedNumber
                                                value={shipping}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />),
                                    }}
                                />
                            </dd>
                        </dl>
                        <dl className="Totals-grandTotal">
                            <dt>Grand Total</dt>
                            <dd>
                                <FormattedMessage
                                    id="grandTotal"
                                    defaultMessage={`{tax, plural,
                                        =0 {----}
                                        other {€ {formattedGrandTotal}}
                                    }`}
                                    values={{
                                        tax,
                                        formattedGrandTotal: (
                                            <FormattedNumber
                                                value={subtotal}
                                                minimumFractionDigits={2}
                                                maximumFractionDigits={2}
                                            />),
                                    }}
                                />
                            </dd>
                        </dl>
                        {cart.length ? (
                            <div>
                                <button className="Totals-stdCheckout">
                                    Checkout
                                </button>
                            </div>
                        ) : ''}
                    </div>
                </aside>
            </div>
        </div>
    );
};

StandardCheckout.propTypes = {
    cart: PropTypes.array.isRequired,
    currency: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        cart: state.cart,
        currency: getCurrency(state),
    };
};

export default showIfLocation(connect(
    mapStateToProps,
)(StandardCheckout));
