import React from 'react';
import {showIfLocation, Link} from 'SimpleHistory';
import './VRPSetupSuccess.css';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { parseParamsFromUrl, maskValue } from 'util.js';
import base64url from 'base64url';
import vrpActions from 'actions/vrp.js';
import { VRP_TYPE_NON_SWEEPING, VRP_TYPE_SWEEPING } from '../../../config/constants';

const extractInfo = ({ vrp, cache }) => {
    return {
        memberId: vrp.memberId,
        id: vrp.id, // vrp id
        refId: vrp.initiation.refId,
        createdDateTime: vrp.createdDateTime,
        bankId: vrp.initiation.bankId,
        startDateTime: vrp.initiation.startDateTime,
        endDateTime: vrp.initiation.endDateTime,
        periodicLimits: vrp.initiation.periodicLimits,
        maximumIndividualAmount: vrp.initiation.maximumIndividualAmount,
        remittanceInformationPrimary: vrp.initiation.remittanceInformationPrimary,
        currency: vrp.initiation.currency,
        vrpType: vrp.initiation.vrpType ===  'OTHER'
            ? VRP_TYPE_NON_SWEEPING
            : VRP_TYPE_SWEEPING,
        localInstrument: vrp.initiation.localInstrument,
        ...(vrp.initiation.debtor && {
            debtor: {
                ...vrp.initiation.debtor,
                ...(vrp.initiation.debtor.iban && {
                    iban: maskValue(vrp.initiation.debtor.iban),
                }),
                ...(vrp.initiation.debtor.accountNumber && {
                    accountNumber: maskValue(vrp.initiation.debtor.accountNumber),
                }),
            },
        }),
        cardNumber: cache.cardNumber,
        accountNickName: cache.accountNickName,
        issuingBank: cache.issuingBank,
        sessionId: cache.sessionId,
    };
};

const VRPSetupSuccess = ({ setVRPConsent}) => {
    const params = parseParamsFromUrl(window.location.href);
    const data = JSON.parse(base64url.decode(params.data));
    const redirectState = JSON.parse(base64url.decode(params['redirect-state']));
    setVRPConsent(extractInfo({ vrp: data, cache: redirectState }));

    return (
        <div className='VRPSetup-success'>
            <div className='VRPSetup-success-details'>
                <div className='VRPSetup-success-title'>
                    <span className='VRPSetup-success-text'>
                        <h3>{'Setup Auto Payment Status:'}</h3>
                    </span>
                    <span className='VRPSetup-success-status'>
                        <h3 className='green'>{data.status ? data.status : 'Authorized'}</h3>
                    </span>
                </div>
                <div className='VRPSetup-success-body'>
                    {'Your auto payment setup has successfully completed'}
                </div>
                <div className='VRPSetup-success-button-container'>
                    <Link
                        to="/services"
                        className="VRPSetup-success-button">
                        Close
                    </Link>
                </div>
            </div>
        </div>
    );
};

VRPSetupSuccess.propTypes = {
    setVRPConsent: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
    setVRPConsent: vrpActions.setVRPConsent,
};

export default showIfLocation(connect(null, mapDispatchToProps)(VRPSetupSuccess));
