import React from 'react';
import {showIfLocation, Link} from 'SimpleHistory';
import './InsufficientFunds.css';

class InsufficientFunds extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="InsufficientFunds">
                <h1 className="InsufficientFunds-title">Insufficient Funds</h1>
                <div className="InsufficientFunds-desc">
                    <p>
                        {'You have insufficient funds in your account. Please'} <Link to="/cart">try again</Link>
                    </p>
                </div>
            </div>
        );
    }
}

export default showIfLocation(InsufficientFunds);
