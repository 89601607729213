import React, {Component} from 'react';
import PropTypes from 'prop-types';
import style from './index.css';
import TextInput from './TextInput.js';
import {connect} from 'react-redux';
import Credentials from './Credentials';
import configMenuActions from 'actions/configMenuActions';
import {customDestination as customDestinationConfig, webAppUrl, customSource, defaults, TYPE_ONE, TYPE_TWO, TYPE_TWO_CALLBACK, TOKEN_API_URL} from 'config/constants';
import {getCurrency} from 'reducers';
import {supportedCurrencies, requiredAccountFields, requiredSourceConfig, displayName, getFieldValidation, getSourceFieldValidation, getValidSourceSchemes, validateCredentials, getIbanCase, fetchBanksOrCountries, deepTrim } from 'util.js';
import classNames from 'classnames/bind';

const cx = classNames.bind(style);

class CustomDestinationInput extends Component {
    constructor() {
        super();
        this.state = {
            isOpen: false,
            errors: false,
            betaVersion: false,
            webappInIframe: false,
            webappV2Modal: false,
            isMemberTypeTwo: false,
            isCafEnabled: false,
            credentials: undefined,
            sourceBankId: {
                isValid: true,
            },
        };
    }

    componentDidMount() {
        this.open();
        const memberType = this.props.memberType;
        if (memberType === TYPE_TWO) {
            this.setState({
                isMemberTypeTwo: true,
                webAppEnabled: false,
                receiveCallback: false,
            });
        } else if (memberType === TYPE_TWO_CALLBACK) {
            this.setState({
                isMemberTypeTwo: true,
                webAppEnabled: false,
                receiveCallback: true,
            });
        } else {
            this.setState({
                webAppEnabled: this.props.webAppEnabled,
            });
        }
        this.setState({webappInIframe: this.props.webappInIframe, webappV2Modal: this.props.webappV2Modal});
        this.setState({isOpen: Object.keys(this.props.account).length === 0});
        this.props.clientName !== 'southside' && this.setState({
            webAppEnabled: defaults[this.props.clientName]?.defaultWebAppEnabled || true,
            testPricesEnabled: defaults[this.props.clientName]?.testPricesEnabled || false,
            isMemberTypeTwo: defaults[this.props.clientName]?.defaultMemberType === TYPE_TWO,
            scheme: defaults[this.props.clientName]?.defaultDestinationScheme || '',
            currency: defaults[this.props.clientName]?.defaultCurrency || '',
            sourceCountry: defaults[this.props.clientName]?.defaultSourceCountry || '',
            devKey: defaults[this.props.clientName]?.defaultDevKey || '',
            account: {...this.props.account},
        });
    }

    confirm(e) {
        e && e.preventDefault();
        this.saveLocalState();
        this.close();
    }

    open() {
        this.resetLocalState();
        this.setState({isOpen: true});
    }

    close() {
        this.setState({isOpen: false});
    }

    resetLocalState() {
        this.setState({
            account: {...this.props.account},
            currency: this.props.currency || '',
            scheme: this.props.scheme || '',
            devKey: this.props.devKey || '',
            sourceAccount: this.props.sourceAccount,
            sourceBankId: {
                value: this.props.sourceBankId || '',
                isValid: !!this.state.sourceBankId?.isValid,
            },
            sourceScheme: this.props.sourceScheme,
            sourceCountry: this.props.sourceCountry || '',
            webAppEnabled: this.props.webAppEnabled,
            betaVersion: this.props.betaVersion,
            isCafEnabled: this.props.isCafEnabled,
            credentials: this.props.credentials || undefined,
            remittanceReference: this.props.remittanceReference || '',
        });
    }

    saveLocalState() {
        this.props.setAccount(deepTrim(this.state.account));
        this.props.setCurrency(this.state.currency);
        this.props.setScheme(this.state.scheme);
        this.props.setDevKey(this.state.devKey?.trim());
        this.props.setSourceAccount(deepTrim(this.state.sourceAccount), this.state.sourceScheme, this.state.sourceBankId?.value?.trim());
        this.props.setSourceCountry(this.state.sourceCountry);
        this.props.setWebAppEnabled(this.state.webAppEnabled);
        this.props.setBetaVersion(this.state.betaVersion);
        this.props.setWebappInIframe(this.state.webappInIframe);
        this.props.setWebappV2Modal(this.state.webappV2Modal);
        this.props.setMemberType(this.getMemberType());
        this.props.setCafEnabled(this.state.isCafEnabled);
        this.props.setCredentials(
            deepTrim(validateCredentials(
                this.state.credentials,
                this.props.selectedBank?.credentialFields,
                this.state.sourceBankId?.value,
                this.state.isMemberTypeTwo && !this.state.webAppEnabled,
            )),
        );
        this.props.setRemittanceReference(this.state.remittanceReference);
    }

    getMemberType() {
        if (this.state.isMemberTypeTwo) {
            if (this.state.receiveCallback) {
                return TYPE_TWO_CALLBACK;
            } else return TYPE_TWO;
        } else return TYPE_ONE;
    }

    formatAccount(scheme, account, currency) {
        let output = `${displayName(scheme)} (${currency}); `;
        const accountFields = requiredAccountFields(scheme);
        for (let i = 0; i < accountFields.length; i++) {
            if (!account[accountFields[i]]) {
                return 'Configuration';
            }
            output += `${displayName(accountFields[i])}: ` +
                `${account[accountFields[i]]}, `;
        }
        return output.substr(0, output.length - 2);
    }

    selectOptions(options) {
        const items = [];
        for (let i = 0; i < options.length; i++) {
            items.push(
                <option key={options[i]} value={options[i]}>
                    {displayName(options[i])}
                </option>);
        }
        return items;
    }

    accountField(fieldName, index, validation = {}) {
        return (<TextInput
            placeholder={`${displayName(fieldName)} ${validation.description || ''}`}
            value={this.state.account[fieldName] || ''}
            onChange={e => this.setState({ account: { ...this.state.account, [fieldName]: e.target.value } })}
            key={fieldName}
            id= {fieldName}
            pattern={validation.pattern}
            autoFocus={!index} />);
    }

    reset() {
        window.localStorage.clear();
        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('src', `${webAppUrl}/config`);
        document.getElementsByTagName('body')[0].appendChild(ifrm);
        ifrm.onload = () => {
            window.location.reload();
        };
    }

    setCredentials = credentials => {
        this.setState({
            credentials: {
                ... this.state.credentials,
                ... credentials,
            },
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if ((prevState.sourceBankId?.value?.trim() !== this.state.sourceBankId?.value?.trim())
            || (prevState?.devKey?.trim() !== this.state.devKey?.trim())) {
            this.handleBankId();
        }
    }

    handleBankId = () => {
        // Clears running timer and starts a new one each time the user types
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
            fetchBanksOrCountries({url: TOKEN_API_URL + '/banks?ids=' + this.state.sourceBankId?.value?.trim(), devKey: this.state.devKey})
                .then(res => {
                    if (res.status === 200) {
                        const bankInfo = res.data?.banks?.[0];
                        if (bankInfo) {
                            this.props.setSelectedBankInfo(bankInfo);
                            this.setState({
                                displaySpinner: false,
                                sourceBankId: {
                                    ...this.state.sourceBankId,
                                    isValid: true,
                                },
                            });
                        } else {
                            this.props.setSelectedBankInfo();
                            this.setState({
                                displaySpinner: false,
                                sourceBankId: {
                                    ...this.state.sourceBankId,
                                    isValid: false,
                                },
                            });
                        }
                    } else {
                        // eslint-disable-next-line no-console
                        console.error('Get banks api fails with status', res.status, res.data);
                        this.props.setSelectedBankInfo();
                        this.setState({
                            displaySpinner: false,
                            sourceBankId: {
                                ...this.state.sourceBankId,
                                isValid: false,
                            },
                        });
                    }
                })
                .catch(error => {
                    // eslint-disable-next-line no-console
                    console.error('Get banks api fails with status', error.response?.status, error.response?.data);
                    this.props.setSelectedBankInfo();
                    this.setState({
                        displaySpinner: false,
                        sourceBankId: {
                            ...this.state.sourceBankId,
                            isValid: false,
                        },
                    });
                });
        }, 700);
    }

    getRequiredCreds = () => {
        if (this.state.isMemberTypeTwo && !this.state.webAppEnabled) {
            return this.props.selectedBank?.credentialFields || null;
        }
        return null;
    };

    sourceAccountField(fieldName, index, validation = {}) {
        const ibanCase = !!this.getRequiredCreds() && getIbanCase(this.props.selectedBank?.credentialFields);
        return (
            <React.Fragment key={index}>
                <TextInput
                    placeholder={`${displayName(fieldName)} ${validation.description || ''}`}
                    value={this.state.sourceAccount && this.state.sourceAccount[fieldName]  || ''}
                    onChange={e => this.setState({
                        ...this.state,
                        sourceAccount: {
                            ...this.state.sourceAccount,
                            [fieldName]: e.target.value,
                        },
                        credentials: {
                            ...this.state.credentials,
                            [ibanCase]: fieldName.toUpperCase() === 'IBAN' ? e.target.value : undefined,
                        },
                    })}
                    key={'source_' + fieldName}
                    id={'source_' + fieldName}
                    pattern={validation.pattern}
                    autoFocus={!index}
                    required={!!(ibanCase && fieldName.toUpperCase() === 'IBAN')} />
            </React.Fragment>
        );

    }

    render() {
        const requiredCredentials = this.getRequiredCreds();
        const inner = !this.state.isOpen
            ? this.formatAccount(this.props.scheme, this.props.account, this.props.currency)
            : (
                <div className={'CustomDestinationInput-subcontainer'}>
                    <select value={this.state.scheme}
                        onChange={e => this.setState({ scheme: e.target.value,
                            currency: supportedCurrencies(e.target.value)[0] || '',
                            sourceScheme: (e.target.value === 'euDomesticNonEuro' ? 'iban' : this.state.sourceScheme)})}>
                        {this.selectOptions(customDestinationConfig.supportedSchemes)}
                    </select>
                    <select value={this.state.currency}
                        onChange={e => this.setState({ currency: e.target.value })}>
                        {this.selectOptions(supportedCurrencies(this.state.scheme))}
                    </select>
                    <span>Destination</span>
                    <form id={'CustomDestinationInput-form'} onSubmit={e => this.confirm(e)}>
                        {requiredAccountFields(this.state.scheme)
                            .map((fieldName, i) =>
                                this.accountField(fieldName, i, getFieldValidation(this.state.scheme, fieldName)))}

                        {/* Source Account fields */}
                        <span id="SourceAccount-span">Source Account</span>
                        <br />
                        <select value={this.state.sourceScheme }
                            onChange={e => this.setState({ sourceScheme: e.target.value })}>
                            {this.selectOptions(getValidSourceSchemes(customSource.supportedSourceSchemes, this.state.scheme))}
                        </select>
                        <br />
                        {requiredSourceConfig(this.state.sourceScheme)
                            .map((fieldName, i) =>
                                this.sourceAccountField(fieldName, i, getSourceFieldValidation(this.state.sourceScheme, fieldName)))}
                        <TextInput
                            placeholder={`${displayName('bankId')}`}
                            value={this.state.sourceBankId.value || ''}
                            className={cx({'Invalid_input': !this.state.sourceBankId?.isValid && !this.state.displaySpinner && this.state.sourceBankId.value})}
                            onChange={e => this.setState({
                                ...this.state,
                                sourceBankId: {
                                    ...this.state.sourceBankId,
                                    value: e.target.value,
                                },
                                displaySpinner: true,
                            })}
                            displaySpinner={this.state.displaySpinner}
                            id={'bank-id'}
                            key={'bankId'} />
                        <TextInput
                            value={this.state.remittanceReference}
                            id= {'Remittance-reference'}
                            onChange={e => this.setState({
                                ...this.state,
                                remittanceReference: e.target.value,
                            })}
                            pattern='^[A-Za-z]+$'
                            placeholder={'Remittance reference (Alphabets only)'} />

                        <Credentials
                            onKeyDown={e => e.key === 'Enter' && this.confirm()}
                            requiredCredentials={requiredCredentials}
                            setCredentials={this.setCredentials}
                            credentialsValue={this.state.credentials}
                            sourceScheme={this.state.sourceScheme} />
                        <span id="Extra-param-span">Additional fields (optional)</span>
                        <br />
                        <TextInput
                            onKeyDown={e => e.key === 'Enter' && this.confirm()}
                            value={this.state.sourceCountry}
                            id= {'SourceCountry-input'}
                            onChange={e => this.setState({ sourceCountry: e.target.value })}
                            placeholder={'Source country code (2 letter ISO Code)'}
                            pattern='^[a-zA-Z]{2}$' />
                        <TextInput
                            onKeyDown={e => e.key === 'Enter' && this.confirm()}
                            value={this.state.devKey}
                            onChange={e => this.setState({ devKey: e.target.value })}
                            id= {'Dev-key'}
                            placeholder={'Dev Key (Optional)'} />
                    </form>

                    {this.state.webAppEnabled &&
                        <div className='Config-betaVersion'>
                            <div style={{float: 'left'}}>
                                <span>Open Web App V1 in iframe</span>
                            </div>
                            <div style={{float: 'right'}}>
                                <label className="Config-switch">
                                    <input
                                        type="checkbox"
                                        checked={this.state.webappInIframe}
                                        onChange={
                                            () =>
                                                this.setState({ webappInIframe: !this.state.webappInIframe})
                                        }
                                    />
                                    <span className="Config-slider round"></span>
                                </label>
                            </div>
                        </div>
                    }
                    {this.state.webAppEnabled &&
                        <div className='Config-betaVersion'>
                            <div style={{float: 'left'}}>
                                <span>Open Web App V2 in modal</span>
                            </div>
                            <div style={{float: 'right'}}>
                                <label className="Config-switch">
                                    <input
                                        type="checkbox"
                                        checked={this.state.webappV2Modal}
                                        onChange={
                                            () =>
                                                this.setState({ webappV2Modal: !this.state.webappV2Modal })
                                        }
                                    />
                                    <span className="Config-slider round"></span>
                                </label>
                            </div>
                        </div>
                    }
                    <div className='Config-enableCaf'>
                        <div style={{float: 'left'}}>
                            <span>Enable confirm funds</span>
                        </div>
                        <div style={{float: 'right'}}>
                            <input type="checkbox"
                                id="chkConfirmFunds"
                                checked={this.state.isCafEnabled}
                                onChange={() => {
                                    this.setState({ isCafEnabled: !this.state.isCafEnabled});
                                }}
                            />
                        </div>
                    </div>
                    <div className={'Config-option Config-prices'}>
                        <button className={!this.props.testPricesEnabled && 'set' || ''}
                            onClick={() => this.props.setTestPrices(false)}>
                            Demo Prices
                        </button>
                        <button className={this.props.testPricesEnabled && 'set' || ''}
                            onClick={() => this.props.setTestPrices(true)}>
                            Test Prices
                        </button>
                    </div>
                    <div className={'Config-option Config-webAppEnabled'}>
                        <button className={this.state.webAppEnabled && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                webAppEnabled: true,
                            })}>
                            {'Use Web-App'}
                        </button>
                        <button className={!this.state.webAppEnabled && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                webAppEnabled: false,
                                webappV2Modal: false,
                                webappInIframe: false,
                            })}>
                            {'Don\'t use Web-App'}
                        </button>
                    </div>
                    <div className={'Config-option Config-webAppEnabled'}>
                        <button className={!this.state.isMemberTypeTwo && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                isMemberTypeTwo: false,
                            })}>
                            {'Type 1'}
                        </button>
                        <button className={this.state.isMemberTypeTwo && 'set' || ''}
                            onClick={() => this.setState({
                                ...this.state,
                                isMemberTypeTwo: true,
                                receiveCallback: false,
                            })}>
                            {'Type 2'}
                        </button>
                    </div>
                    { this.state.isMemberTypeTwo &&
                        <div className={'Config-option Config-webAppEnabled'}>
                            {!this.state.webAppEnabled &&
                            <button className={this.state.receiveCallback && 'set' || ''}
                                onClick={() => this.setState({
                                    ...this.state,
                                    receiveCallback: true,
                                })}>
                                {'TPP Callback'}
                            </button>
                            }
                            <button className={!this.state.receiveCallback && 'set' || ''}
                                onClick={() => this.setState({
                                    ...this.state,
                                    receiveCallback: false,
                                })}>
                                {'Token Callback'}
                            </button>
                        </div>
                    }
                    <div className={'Config-control'}>
                        <button
                            className={'Config-reset'}
                            onClick={this.reset}>
                            Reset
                        </button>
                        <button
                            className={'Config-submit'}
                            disabled={!!this.state.displaySpinner}
                            form={'CustomDestinationInput-form'}>
                            Confirm
                        </button>
                    </div>
                </div>);
        return (
            <div
                className={'CustomDestinationInput-container ' + (this.state.isOpen && 'opened'
                    || undefined)}
                onClick={() => !this.state.isOpen && this.open()}>
                {inner}
            </div>
        );
    }
}

CustomDestinationInput.propTypes = {
    account: PropTypes.object,
    currency: PropTypes.string,
    scheme: PropTypes.string,
    devKey: PropTypes.string,
    sourceCountry: PropTypes.string,
    sourceBankId: PropTypes.string,
    sourceScheme: PropTypes.string.isRequired,
    sourceAccount: PropTypes.object.isRequired,
    webAppEnabled: PropTypes.bool.isRequired,
    testPricesEnabled: PropTypes.bool,
    setAccount: PropTypes.func.isRequired,
    setCurrency: PropTypes.func.isRequired,
    setScheme: PropTypes.func.isRequired,
    setDevKey: PropTypes.func.isRequired,
    setSourceCountry: PropTypes.func.isRequired,
    setTestPrices: PropTypes.func.isRequired,
    setSourceAccount: PropTypes.func.isRequired,
    setWebAppEnabled: PropTypes.func.isRequired,
    setBetaVersion: PropTypes.func.isRequired,
    betaVersion: PropTypes.bool,
    memberType: PropTypes.string,
    setMemberType: PropTypes.func,
    clientName: PropTypes.string,
    isCafEnabled: PropTypes.bool,
    setCafEnabled: PropTypes.func,
    setCredentials: PropTypes.func,
    setSelectedBankInfo: PropTypes.func,
    selectedBank: PropTypes.object,
    credentials: PropTypes.object,
    remittanceReference: PropTypes.string,
    setRemittanceReference: PropTypes.func,
    webappInIframe: PropTypes.bool,
    webappV2Modal: PropTypes.bool,
    setWebappInIframe: PropTypes.func,
    setWebappV2Modal: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        account: state.configMenu.customDestination.account,
        scheme: state.configMenu.customDestination.scheme,
        devKey: state.configMenu.customDestination.devKey,
        testPricesEnabled: state.configMenu.customDestination.testPricesEnabled,
        currency: getCurrency(state),
        sourceAccount: state.configMenu.source.account,
        sourceScheme: state.configMenu.source.scheme,
        sourceBankId: state.configMenu.source.bankId,
        sourceCountry: state.configMenu.source.sourceCountry,
        webAppEnabled: state.configMenu.webAppEnabled,
        betaVersion: state.configMenu.betaVersion,
        webappInIframe: state.configMenu.webappInIframe,
        webappV2Modal: state.configMenu.webappV2Modal,
        memberType: state.configMenu.memberType,
        clientName: state.clientName,
        isCafEnabled: state.configMenu.isCafEnabled,
        selectedBank: state.configMenu.source.bankInfo,
        credentials: state.configMenu.source.credentials,
        remittanceReference: state.configMenu.remittanceReference,
    };
};

const mapDispatchToProps = {
    setAccount: configMenuActions.setCustomDestinationAccount,
    setCurrency: configMenuActions.setCustomDestinationCurrency,
    setScheme: configMenuActions.setCustomDestinationScheme,
    setDevKey: configMenuActions.setDevKey,
    setSourceCountry: configMenuActions.setSourceCountry,
    setTestPrices: configMenuActions.setTestPrices,
    setSourceAccount: configMenuActions.setSourceAccount,
    setWebAppEnabled: configMenuActions.setWebAppEnabled,
    setBetaVersion: configMenuActions.setBetaVersion,
    setMemberType: configMenuActions.setMemberType,
    setCafEnabled: configMenuActions.setCafEnabled,
    setCredentials: configMenuActions.setCredentials,
    setSelectedBankInfo: configMenuActions.setSelectedBankInfo,
    setRemittanceReference: configMenuActions.setRemittanceReference,
    setWebappInIframe: configMenuActions.setWebappInIframe,
    setWebappV2Modal: configMenuActions.setWebappV2Modal,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CustomDestinationInput);
