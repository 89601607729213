import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation, Link} from 'SimpleHistory';
import {connect} from 'react-redux';

const AdhocPayError = ({ error }) => {
    return (
        <div className="Error">
            <h1 className="Error-title">Error processing adhoc payment</h1>
            <div className="Error-desc">
                <p>
                    {'There was an error processing your adhoc payment.'}<br />
                    {`${error}. Please `}
                    <Link to="/services" className='Try-again-link'>try again</Link>
                </p>
            </div>
        </div>
    );
};

AdhocPayError.propTypes = {
    error: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        error: state.vrp.error,
    };
};

export default showIfLocation(connect(mapStateToProps)(AdhocPayError));
