import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {showIfLocation, Link} from 'SimpleHistory';
import './Error.css';

class Error extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const error = this.props.error === 'FAILURE_INSUFFICIENT_FUNDS'
            ? 'Insufficient funds'
            : this.props.error;
        return (
            <div className="Error">
                <h1 className="Error-title">Error processing payment</h1>
                <div className="Error-desc">
                    <p>
                        {'There was as error processing your payment:'}<br />
                        {`${error}. Please `}
                        <Link to="/cart" className='Try-again-link'>try again</Link>
                    </p>
                </div>
            </div>
        );
    }
}
Error.propTypes = {
    error: PropTypes.string.isRequired,
};

const mapStateToProps = state => {
    return {
        error: state.transferError,
    };
};

export default showIfLocation(connect(mapStateToProps)(Error));
