import React from 'react';
import {showIfLocation} from 'SimpleHistory';
import './SufficientFunds.css';

class SufficientFunds extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <div className="SufficientFunds">
                <h1 className="SufficientFunds-title">Thank You!</h1>
                <div className="SufficientFunds-desc">
                    <p>
                        You have sufficient funds in your account. Feel free to contact us, if you
                        have any issues.
                    </p>
                </div>
            </div>
        );
    }
}

export default showIfLocation(SufficientFunds);
