// Custom hook
import { useEffect, useState } from 'react';

const getInitialValue = (key, defaultValue) => {
    try {
        if (key) {
            const persistedValue = JSON.parse(window.localStorage.getItem(key));
            if (persistedValue) {
                return persistedValue;
            }
            return defaultValue;
        }
        return {};
    } catch (e) {
        //Ignore
        console.log('Error fetching data from local storage', e);
    }
};

const useLocalStorage = (key, defaultValue) => {
    const [value, setValue] = useState(() => getInitialValue(key, defaultValue));

    useEffect(() => {
        try {
            window.localStorage.setItem(key, JSON.stringify(value));
        } catch (e) {
            //Ignore
            console.error('Error setting data to local storage', e);
        }
    }, [value]);

    return [value, setValue];
};

export default useLocalStorage;
