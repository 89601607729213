import axios from 'axios';
const RandExp = require('randexp');
const {v4} = require('uuid');

import {
    CLIENTS_URL_MAPPINGS,
    customDestination as customDestinationConfig,
    displayNames,
    regexForFrequency,
    schemes as schemeConfig,
    sourceScheme as sourceSchemeConfig,
    version,
} from 'config/constants';
import { countryMap } from './config/countries';

export const getSubtotal = (items, testPricesEnabled) =>
    items.reduce((sum, item) =>
        sum + ((testPricesEnabled ? item.testPrice : item.price) * item.qty), 0);

const merchantInstance = axios.create({
    baseURL: window.location.origin,
});

export const MerchantClient = {
    getMerchantUsername: () => {
        return new Promise(resolve => {
            merchantInstance({
                method: 'get',
                url: '/merchant',
            }).then(resp => {
                resolve(resp.data.alias);
            });
        });
    },
    getTokenRequestUrl: (amount, currency, destination, devKey, extra, option, source, webAppEnabled, bulkTransfers, sourceCountry, betaVersionEnabled, memberType, clientName, cafEnabled, remittanceReference, credentials, webappV2Enabled) => {
        const data = {
            amount,
            currency,
            destination,
            devKey,
            extra,
            option,
            source,
            webAppEnabled,
            bulkTransfers,
            sourceCountry,
            betaVersionEnabled,
            memberType,
            clientName,
            cafEnabled,
            remittanceReference,
            credentials,
            webappV2Enabled,
        };
        return merchantInstance({
            method: 'post',
            url: '/getTokenRequestUrl',
            data,
        });
    },
    instructPayment: data => {
        return merchantInstance({
            method: 'post',
            url: '/gateway',
            data,
        });
    },
};

export const objKeysToList = obj => {
    const items = [];
    for (const k in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
            items.push(k);
        }
    }
    return items;
};

export const getAnyProperty = obj => {
    for (const k in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, k)) {
            return k;
        }
    }
};

export const loadState = () => {
    try {
        const serializedState = localStorage.getItem('state');
        if (!serializedState) {
            return undefined;
        }
        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const saveState = state => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem('state', serializedState);
    } catch (e) {
        // Ignore write errors.
    }
};

export const supportedCurrencies = scheme => {
    const currencies = schemeConfig[scheme].currencies;
    return customDestinationConfig.supportedCurrencies
        .filter(currency => currencies.includes(currency));
};

export const requiredAccountFields = (scheme, accountConfig = schemeConfig) => {
    return Object.keys(accountConfig[scheme].accountFields);
};

export const requiredMandatoryFields = (scheme, accountConfig = schemeConfig) => {
    const accountFields = accountConfig[scheme].accountFields;
    return Object.keys(accountConfig[scheme].accountFields)
        .filter(field => !accountFields[field]?.isOptional);
};

export const requiredSourceConfig = scheme => {
    return Object.keys(sourceSchemeConfig[scheme].accountFields);
};

const filterAccountFields = (scheme, account, accountConfig) => {
    const accountFields = requiredAccountFields(scheme, accountConfig);
    const filtered = {};
    Object.keys(account)
        .filter(field => accountFields.includes(field))
        .forEach(field => filtered[field] = account[field]);
    return filtered;
};

const filterSourceAccountFields = (scheme, account) => {
    const filtered = {};
    const requiredFields = requiredSourceConfig(scheme);
    Object.keys(account)
        .filter(field => requiredFields.includes(field))
        .forEach(field => filtered[field] = account[field]);
    return filtered;
};

export const displayName = name => {
    return displayNames[name] || name;
};

export const formatCustomDestination = (scheme, account, memberType) => {
    const filtered = filterAccountFields(scheme, account);
    if (scheme === 'elixir') {
        // TODO: Use the transfer destionation after the app is updated.
        return validateAccount(scheme, account)
            ? {
                [scheme]: filtered,
                customerData: getCustomerData(memberType),
            } : {};
    } else {
        return validateAccount(scheme, account)
            ? {
                account: {[scheme]: filtered},
                customerData: getCustomerData(memberType),
            } : {};
    }
};

export const formatSourceAccount = (scheme, account, bankId, destinationScheme) => {
    let isFiltered = true;
    if (destinationScheme === 'euDomesticNonEuro') {
        isFiltered = account.bic && account.iban ? true : false;
    }
    if (validateAccount(scheme, account, sourceSchemeConfig)) {
        const filtered = filterSourceAccountFields(scheme, account);
        return (validateAccount(scheme, filtered, sourceSchemeConfig) || account.iban) && bankId && isFiltered
            ? {
                account: {[scheme]: filtered},
                bankId: bankId,
            } : {};
    } else {
        return isFiltered
            ? {
                bankId: bankId,
            } : {};
    }
};

export const getFieldValidation = (scheme, fieldName) => {
    try {
        return schemeConfig[scheme].validation[fieldName];
    } catch (e) {
        return undefined;
    }
};

const getCustomerData = (memberType = 'type1') => {
    return {
        legalNames: customDestinationConfig.customerNames,
        address: customDestinationConfig.address[memberType],
    };
};

export const getSourceFieldValidation = (scheme, fieldName) => {
    try {
        return sourceSchemeConfig[scheme].validation[fieldName];
    } catch (e) {
        return undefined;
    }
};

export const validateAccount = (scheme, account, accountConfig) => {
    if (!scheme) return null;
    const accountFields = filterAccountFields(scheme, account, accountConfig);
    const requiredFields = requiredMandatoryFields(scheme, accountConfig);
    for (const field of requiredFields) {
        if (!accountFields[field]) return false;
    }
    return true;
};

export const syncLocalStorageVersion = () => {
    const localStorageVersion = localStorage.getItem('version');
    if (!localStorageVersion || localStorageVersion !== version) {
        localStorage.clear();
        localStorage.setItem('version', version);
        location.reload();
    }
};

export const formatCurrentDate = () => {
    let today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0');
    const year = today.getFullYear();
    today = `${year}-${month}-${day}`;
    return today;
};

export const formatUrl = (clientName, url) => {
    const clientBaseUrl = CLIENTS_URL_MAPPINGS[clientName];
    if (clientBaseUrl && clientBaseUrl !== '')
        if (url === '/')
            return '/' + clientBaseUrl;
        else
            return '/' + clientBaseUrl + url;
    else
        return url;
};

export const createBulkTransfers = (items, testPricesEnabled, destination, currency) => {
    const transfers = [];
    const destinationItem = {
        ...destination.account,
        customerData: destination.customerData,
    };
    items.forEach(item => {
        transfers.push({
            amount: (testPricesEnabled ? item.testPrice : item.price) * item.qty,
            currency: currency,
            refId: item.id,
            description: item.title,
            destination: destinationItem,
        });
    });
    return transfers;
};

export const getValidSourceSchemes = (sourceSchemes, destinationScheme) => {
    if (destinationScheme === 'euDomesticNonEuro') {
        return ['iban'];
    } else {
        return sourceSchemes;
    }
};

export const isValidRegex = (freq, value) => {
    const regex = regexForFrequency[freq?.code]?.join('|')?.trim();
    const re = new RegExp(regex);
    return re.test(value);
};

export const isFrequencyPresent = freq => {
    if (Object.keys(regexForFrequency).includes(freq?.code)) {
        return true;
    }
    return false;
};

export const regexFreqExmpls = freq => {
    let index;
    const regexArr = regexForFrequency[freq?.code];
    const result = [];
    for (index in regexArr) {
        const value1 = new RandExp(regexArr[index]).gen();
        const value2 = new RandExp(regexArr[index]).gen();
        result[index] = value1 !== value2 ? [value1, value2] : [value1];
    }
    return result;
};

export const validateCredentials = (actualCredentials, requiredCredentials, bankId, precondition) => {
    if (bankId === '' || !actualCredentials || !precondition) {
        return undefined;
    }
    const actualKeys = [];
    if (requiredCredentials) {
        requiredCredentials.map(data => actualKeys.push(data.id));
        const credentials = Object.keys(actualCredentials)
            .filter(k => actualKeys.includes(k))
            .reduce((res, key) => (res[key] = actualCredentials[key], res), {});
        return credentials;
    } else {
        return undefined;
    }
};

export const getIbanCase = data => {
    let ibanKey;
    data?.filter(d => {
        if (d.id.toUpperCase() === 'IBAN') {
            ibanKey = d.id;
        }
    });
    return ibanKey;
};

export const fetchBanksOrCountries = async({url, devKey}) => {
    return await axios.get(url, {
        headers: {'token-dev-key': devKey ? devKey?.trim() : 'global-test'},
    });
};

export const deepTrim = obj => {
    if (!obj || (!Array.isArray(obj) && typeof obj != 'object')) return obj;
    return Object.keys(obj).reduce(
        (acc, key) => {
            acc[key] =
                typeof obj[key] == 'string'
                    ? obj[key]?.trim()
                    : deepTrim(obj[key]);
            return acc;
        },
        Array.isArray(obj) ? [] : {},
    );
};

// https://github.com/srcagency/country-to-emoji-flag/blob/master/index.js
export const flagFromCountryCode = country => {
    const offset = 127397;
    const A = 65;
    const Z = 90;
    const f = country.codePointAt(0);
    const s = country.codePointAt(1);

    if (country.length !== 2 || f > Z || f < A || s > Z || s < A)
        throw new Error('Not an alpha2 country code');

    return String.fromCodePoint(f + offset) + String.fromCodePoint(s + offset);
};

export const formatCountries = (countries = []) => {
    const map = new Map();
    countries
        .filter(countryCode => !!countryMap[countryCode])
        .map(countryCode => {
            const country = {
                key: flagFromCountryCode(countryCode),
                id: `${countryMap[countryCode]}`,
                code: countryCode.toLowerCase(),
            };
            map[countryMap[countryCode]] = {
                key: `${country.key} ${country.id}`,
                code: country.code,
            };
        });
    return Object.values(
        Object.keys(map)
            .sort()
            .reduce((r, k) => ((r[k] = map[k]), r), {}),
    );
};

export const isEmpty = val => {
    if (typeof(val) === 'string') {
        val = val?.trim();
    }
    if (!val) {
        return true;
    }
    return false;
};

export const formatAsKeyValuePair = arr => {
    return arr.map((v, i) => {
        return { id: i, value: v };
    });
};

export const toIsoDate = date => {
    if (!date) return;
    const tzo = -date.getTimezoneOffset(),
        dif = tzo >= 0 ? '+' : '-',
        pad = function(num) {
            return (num < 10 ? '0' : '') + num;
        };

    return date.getFullYear() +
        '-' + pad(date.getMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes()) +
        ':' + pad(date.getSeconds()) +
        '.' + pad(date.getMilliseconds()) +
        dif + pad(Math.floor(Math.abs(tzo) / 60)) +
        ':' + pad(Math.abs(tzo) % 60);
};

export const parseParamsFromUrl = url => {
    const query = url.split('?')[1];
    const result = {};
    query && query.split('&').forEach(function(part) {
        const item = part.split('=');
        if (result[item[0]]) {
            if (Array.isArray(result[item[0]])) {
                result[item[0]] = [...result[item[0]], decodeURIComponent(item[1])];
            }
            else result[item[0]] = [result[item[0]], decodeURIComponent(item[1])];
        }
        else result[item[0]] = decodeURIComponent(item[1]);
    });
    return result;
};

export const fetchSessionId = () => {
    let id = '';
    try {
        id = sessionStorage.getItem('session-id');
        if (!id) {
            id = v4();
            sessionStorage.setItem('session-id', id);
        }
        return id;
    } catch (e) {
        console.error(e);
    }
};

export const maskValue = value => {
    if (!value) return;
    let visibleStr = value;
    if (value.length >= 3) {
        visibleStr = value.slice(-3);
    }
    return 'XXXXXXXX' + visibleStr;
};
