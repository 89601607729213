import React from 'react';
import {IntlProvider} from 'react-intl';
import CartGrid from 'components/Cart/CartGrid';
import CartTotals from 'components/Cart/CartTotals';
import {showIfLocation, Link} from 'SimpleHistory';
import './Cart.css';

class Cart extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <IntlProvider locale="en">
                <div className="Cart">
                    <nav className="Breadcrumbs">
                        <Link to="/">Home</Link>
                        <Link to="/cart" className="active">Cart</Link>
                    </nav>
                    <section className="Cart-container">
                        <CartGrid />
                        <CartTotals />
                    </section>
                </div>
            </IntlProvider>
        );
    }
}

export default showIfLocation(Cart);
