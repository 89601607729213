import React, { useEffect, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl';
import {showIfLocation, Link} from 'SimpleHistory';
import TokenButton from 'components/TokenButton/TokenButtonAdhoc';
import { memberInfo, TOKEN_API_URL, LOCAL_INSTRUMENTS, VRP_TYPE_SWEEPING } from 'config/constants';
import NoDataIcon from '../../../Icons/NoDataIcon';
import { fetchBanksOrCountries, parseParamsFromUrl } from 'util.js';
import vrpActions from 'actions/vrp.js';
import './AdhocPayForm.css';

const Parent = ({ children, vrpConsentId, vrpType }) => {
    return (
        <IntlProvider locale="en">
            <div className="Cart">
                <nav className="Breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/services">Services</Link>
                    <Link to={`/services/adhocpay?consent-id=${vrpConsentId}&vrpType=${vrpType}`}
                        className="active">AdhocPayment
                    </Link>
                </nav>
                {children && React.cloneElement(children)}
            </div>
        </IntlProvider>
    );
};

Parent.propTypes = {
    children: PropTypes.any,
    vrpConsentId: PropTypes.string,
    vrpType: PropTypes.string,
};

const AdhocPayForm = ({ vrpConsentList, memberId, devKey, setVrpError, vrpType }) => {
    const [isReady, setIsReady] = useState(false);
    const [vrpConsentData, setVrpConsentData] = useState({});
    const [displayErrorPage, setDisplayErrorPage] = useState(false);
    const [bank, setBank] = useState({});
    const [amount, setAmount] = useState('');
    const [invalidAmount, setAmountInvalid] = useState(false);

    const urlParams = new URLSearchParams(window.location.search);
    const vrpConsentId = urlParams.get('consent-id');

    const handleAmount = value => {
        const regExp = /^\d*\.?\d*$/;
        try {
            if (regExp.test(value)) {
                setAmount(value);
                setAmountInvalid(false);
            } else {
                setAmountInvalid(true);
            }
        } catch (e) {
            console.error(e);
            setAmountInvalid(true);
        }
    };

    const fetchBank = useCallback(
        async () => {
            try {
                const id = vrpConsentData?.bankId;
                if (id) {
                    const res = await fetchBanksOrCountries({ url: TOKEN_API_URL + `/banks?memberId=${memberId}&ids=${id}`, devKey});
                    if (res.status === 200) {
                        const bank = res.data?.banks?.[0];
                        setBank(bank);
                    }
                }
            } catch (e) {
                console.error(e);
            }
        },
        [memberId, devKey, vrpConsentData],
    );

    useEffect(
        () => {
            const data = vrpConsentList[vrpConsentId];
            setVrpConsentData(data);
            if (!data) {
                setDisplayErrorPage(true);
            }
        },
        [vrpConsentId, vrpConsentList],
    );

    useEffect(
        () => {
            if (
                amount &&
                currency &&
                remittanceInformationPrimary &&
                vrpConsentId &&
                memberId
            ) {
                setIsReady(true);
            } else {
                setIsReady(false);
            }
        },
        [amount, currency, remittanceInformationPrimary, vrpConsentId, memberId],
    );

    useEffect(
        () => {
            fetchBank();
        },
        [memberId, devKey, vrpConsentData],
    );

    const to = vrpConsentData?.accountNickName;
    const bankName = bank?.name;
    const localInstrument = vrpConsentData?.localInstrument;
    const debtor = vrpConsentData?.debtor;
    const currency = vrpConsentData?.currency;
    const remittanceInformationPrimary = vrpConsentData?.remittanceInformationPrimary;

    let accountNumber, sortCode, iban, bic;
    if (localInstrument === LOCAL_INSTRUMENTS.DOMESTIC) {
        accountNumber = debtor?.accountNumber;
        sortCode = debtor?.sortCode;
    } else if (localInstrument === LOCAL_INSTRUMENTS.IBAN) {
        iban = debtor?.iban;
        bic = debtor?.bic;
    }

    const formData = {
        amount,
        currency,
        remittanceInformationPrimary,
        consentId: vrpConsentId,
        memberId,
        vrpType,
    };

    if (vrpConsentData && Object.keys(vrpConsentData)?.length > 1) {
        return (
            <Parent vrpConsentId={vrpConsentId} vrpType={vrpType}>
                <div className='AdhocPayForm-container'>
                    <div className='AdhocPayForm-title'>
                        Make Adhoc Payment
                    </div>
                    <div className="AdhocPayForm-sub-container">
                        <div className='AdhocPayForm-content'>
                            <section className="AdhocPayForm">
                                <div className='AdhocPayForm-body'>
                                    <div className='AdhocPayForm-row'>
                                        <div className='AdhocPayForm-row-title'>
                                            To:
                                        </div>
                                        <div className='AdhocPayForm-row-detail'>
                                            <span>{to}</span>
                                        </div>
                                    </div>
                                    <div className='AdhocPayForm-row'>
                                        <div className='AdhocPayForm-row-title'>
                                            From:
                                        </div>
                                    </div>
                                    {
                                        accountNumber && (
                                            <div className='AdhocPayForm-row'>
                                                <div className='AdhocPayForm-row-title'>
                                                    Account Number:
                                                </div>
                                                <div className='AdhocPayForm-row-detail'>
                                                    <span>{accountNumber}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        sortCode && (
                                            <div className='AdhocPayForm-row'>
                                                <div className='AdhocPayForm-row-title'>
                                                    Sort Code:
                                                </div>
                                                <div className='AdhocPayForm-row-detail'>
                                                    <span>{sortCode}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        iban && (
                                            <div className='AdhocPayForm-row'>
                                                <div className='AdhocPayForm-row-title'>
                                                    IBAN:
                                                </div>
                                                <div className='AdhocPayForm-row-detail'>
                                                    <span>{iban}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    {
                                        bic && (
                                            <div className='AdhocPayForm-row'>
                                                <div className='AdhocPayForm-row-title'>
                                                    Bic:
                                                </div>
                                                <div className='AdhocPayForm-row-detail'>
                                                    <span>{bic}</span>
                                                </div>
                                            </div>
                                        )
                                    }
                                    <div className='AdhocPayForm-row'>
                                        <div className='AdhocPayForm-row-title'>
                                            Bank:
                                        </div>
                                        <div className='AdhocPayForm-row-detail'>
                                            <span>{bankName}</span>
                                        </div>
                                    </div>
                                    <div className='AdhocPayForm-row'>
                                        <div className='AdhocPayForm-row-title'>
                                            Payment Date:
                                        </div>
                                        <div className='AdhocPayForm-row-detail'>
                                            <span>{new Date()?.toUTCString().slice(0,16)}</span>
                                        </div>
                                    </div>
                                    <div className='AdhocPayForm-row'>
                                        <div className='AdhocPayForm-row-title'>
                                            Amount:
                                        </div>
                                        <div className='AdhocPayForm-row-detail'>
                                            <div className='AdhocPayForm-input-container'>
                                                <input
                                                    className={ invalidAmount ? 'Invalid-input adhoc-amount' : 'adhoc-amount'}
                                                    onChange={e => handleAmount(e.target.value)}
                                                    value={amount}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='AdhocPayForm-row'>
                                        <span>
                                            {'Select Pay to agree to our Terms and Conditions and to initiate payment at your bank provider.'}
                                        </span>
                                    </div>
                                    <div className='AdhocPayForm-buttons-container'>
                                        <div
                                            className='AdhocPayForm-button-container'
                                        >
                                            <Link
                                                to={'/services'}
                                                style={{border: '1px solid var(--color-sellvana-panel-border)'}}
                                                className='AdhocPayForm-button-cancel'
                                            >
                                                Cancel
                                            </Link>
                                        </div>
                                        <div className='AdhocPayForm-button-container'>
                                            <TokenButton
                                                text='Pay'
                                                data={formData}
                                                isReady={isReady}
                                                onError={err => setVrpError(err)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                    </div>
                </div>
            </Parent>
        );
    } else if (displayErrorPage) {
        return (
            <Parent vrpConsentId={vrpConsentId} vrpType={vrpType}>
                <div className='NoDataIcon-container'>
                    <NoDataIcon className='NoDataIcon' />
                    <div className='NoDataIcon-text'>
                        {'No data found for consent id:'}
                    </div>
                    <br />
                    <div className='NoDataIcon-sub-text'>
                        {vrpConsentId}
                    </div>
                </div>
            </Parent>
        );
    } else {
        return (
            <Parent vrpConsentId={vrpConsentId} vrpType={vrpType} />
        );
    }
};

AdhocPayForm.propTypes = {
    vrpConsentList: PropTypes.object,
    memberId: PropTypes.string,
    devKey: PropTypes.string,
    setVrpError: PropTypes.func,
    vrpType: PropTypes.string,
};

const mapStateToProps = state => {
    const vrpType = parseParamsFromUrl(window.location.href)?.['vrp-type'] || VRP_TYPE_SWEEPING;
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;
    const memberId = memberInfo[clientName][memberType]['id'];

    return {
        vrpConsentList: state.vrp?.[vrpType]?.vrpConsentList?.[memberId] || {},
        devKey: state.configMenu.customDestination.devKey,
        memberId,
        vrpType,
    };
};

const mapDispatchToProps = {
    setVrpError: vrpActions.setVrpError,
};

export default showIfLocation(connect(mapStateToProps, mapDispatchToProps)(AdhocPayForm));
