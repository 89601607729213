import React from 'react';
import PropTypes from 'prop-types';
import './index.css';
import {destinations, currencySymbols, customDestination} from 'config/constants';
import {objKeysToList, displayName} from 'util.js';

class DestinationMenu extends React.Component {
    constructor() {
        super();
        // bind events
        this.state = {
            open: false,
        };
        this.toggleMenu = this.toggleMenu.bind(this);
    }

    toggleMenu() {
        this.setState({
            open: !this.state.open,
        });
    }

    reset() {
        window.localStorage.clear();
        const ifrm = document.createElement('iframe');
        ifrm.setAttribute('src', `${window.WEB_APP_URL}/config`);
        document.getElementsByTagName('body')[0].appendChild(ifrm);
        ifrm.onload = () => {
            window.location.reload();
        };
    }

    render() {
        let inner;
        const {
            bank, method, country, setAdvancedConfig, currency, webAppEnabled, setWebAppEnabled,
            sourceBankId, setSourceAccount, onSelectedBank, onSelectedCountry, onSelectedCurrency,
            onSelectedMethod,
        } = this.props;
        const banks = objKeysToList(destinations).sort();
        const methods = objKeysToList(destinations[bank]).sort();
        const countries = objKeysToList(destinations[bank][method]).sort();
        const currencies = objKeysToList(destinations[bank][method][country]).sort();

        const tableRows = Math.max(
            banks.length,
            methods.length,
            countries.length,
            currencies.length);
        if (this.state.open) {
            inner = (
                <div>
                    <div className="DestinationMenu-wrapper">
                        {customDestination && <p className="DestinationMenu-reset" onClick={setAdvancedConfig}>Advanced</p>}
                        <p className="DestinationMenu-reset" onClick={this.reset}>Reset</p>
                        <p className="DestinationMenu-close" onClick={this.toggleMenu}>Close menu</p>
                    </div>
                    <table>
                        <tbody>
                            {Array.apply(0, new Array(tableRows)).map((x, i) => (
                                <tr key={i}>
                                    <td
                                        onClick={() => onSelectedBank(banks[i])}
                                        className="DestinationMenu-col">
                                        <span className={banks[i] === bank
                                            ? 'DestinationMenu-selected'
                                            : ''}>
                                            {i < banks.length ? banks[i] : ''}
                                        </span>
                                    </td>
                                    <td
                                        onClick={() => onSelectedMethod(methods[i])}
                                        className="DestinationMenu-col">
                                        <span className={methods[i] === method
                                            ? 'DestinationMenu-selected'
                                            : ''}>
                                            {i < methods.length ? methods[i] : ''}
                                        </span>
                                    </td>
                                    <td
                                        onClick={() => onSelectedCountry(countries[i])}
                                        className="DestinationMenu-col">
                                        <span className={countries[i] === country
                                            ? 'DestinationMenu-selected'
                                            : ''}>
                                            {i < countries.length ? countries[i] : ''}
                                        </span>
                                    </td>
                                    <td
                                        onClick={() => onSelectedCurrency(currencies[i])}
                                        className="DestinationMenu-col">
                                        <span className={currencies[i] === currency
                                            ? 'DestinationMenu-selected'
                                            : ''}>
                                            {i < currencies.length
                                                ? currencies[i]
                                                + ' (' + currencySymbols[currencies[i]] + ')'
                                                : ''}
                                        </span>
                                    </td>
                                </tr>))}
                        </tbody>
                    </table>
                    <hr />
                    <div className="DestinationMenu-flow">
                        <div className={'DestinationMenu-option Config-webAppEnabled'}>
                            <button className={webAppEnabled && 'set' || ''}
                                onClick={() => setWebAppEnabled(true)}>
                                {'Use Web-App'}
                            </button>
                            <button className={!webAppEnabled && 'set' || ''}
                                onClick={() => setWebAppEnabled(false)}>
                                {'Don\'t use Web-App'}
                            </button>
                        </div>
                        {!webAppEnabled && (<input
                            type="text"
                            placeholder={`${displayName('bankId')}`}
                            className={'DestinationMenu-inputBank'}
                            value={sourceBankId || ''}
                            onChange={e => setSourceAccount(null, '', e.target.value)}
                            onFocus={e => e.target.select()}
                            key={'bankId'} />)}
                    </div>
                </div>);
        } else {
            inner = (
                <div>
                    <p
                        className="DestinationMenu-open"
                        onClick={this.toggleMenu} >
                        Configuration
                    </p>
                </div>
            );
        }
        return <div className='DestinationMenu' >{inner}</div>;
    }
}

DestinationMenu.propTypes = {
    onSelectedBank: PropTypes.func,
    onSelectedMethod: PropTypes.func,
    onSelectedCountry: PropTypes.func,
    onSelectedCurrency: PropTypes.func,
    setAdvancedConfig: PropTypes.func,
    setSourceAccount: PropTypes.func,
    setWebAppEnabled: PropTypes.func,
    bank: PropTypes.string,
    method: PropTypes.string,
    country: PropTypes.string,
    currency: PropTypes.string,
    sourceBankId: PropTypes.string,
    webAppEnabled: PropTypes.bool,
};

export default DestinationMenu;
