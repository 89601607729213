import React from 'react';
import {showIfLocation, Link} from 'SimpleHistory';
import './AdhocPaySuccess.css';

const AdhocPaySuccess = () => {
    return (
        <div className='AdhocPay-success'>
            <div className='AdhocPay-success-details'>
                <div className='AdhocPay-success-title'>
                    <span className='AdhocPay-success-text'>
                        <h3>{'Adhoc Payment Status:'}</h3>
                    </span>
                    <span className='AdhocPay-success-status'>
                        <h3 className='green'>{'Successful'}</h3>
                    </span>
                </div>
                <div className='AdhocPay-success-body'>
                    {'Your adhoc payment has successfully completed'}
                </div>
                <div className='AdhocPay-success-button-container'>
                    <Link
                        to="/services"
                        className="AdhocPay-success-button">
                        Close
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default showIfLocation(AdhocPaySuccess);
