import React from 'react';
import {showIfLocation} from 'SimpleHistory';
import './CheckoutEnd.css';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

class Paired extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            providerDetails: {},
        };
        this.setProviderDetails = this.setProviderDetails.bind(this);
    }

    setProviderDetails() {
        const urlParams = new URLSearchParams(window.location.search);
        this.setState({
            providerDetails: Object.keys(this.props.providerDetails).length === 0 && {
                id: urlParams.get('id') ? urlParams.get('id') : undefined,
                status: urlParams.get('status') ? urlParams.get('status') : undefined,
            } || this.props.providerDetails,
        });
    }

    componentDidMount() {
        this.setProviderDetails();
    }

    render() {
        return (
            <div className="CheckoutEnd">
                <h1 className="CheckoutEnd-title">Thank You!</h1>
                <div className="CheckoutEnd-desc">
                    {this.state.providerDetails.id &&
                    <p> {`Provider Id: ${this.state.providerDetails.id} ,`}</p>
                    || null}
                    {this.state.providerDetails.status &&
                    <p> {`Provider Status: ${this.state.providerDetails.status} `}</p>
                    || null}
                    <p>
                        Your transaction has gone through, and your item(s) will be
                        shipped within the next few days. Feel free to contact us, if you
                        have any issues with your order.
                    </p>
                </div>
            </div>
        );
    }
}

Paired.propTypes = {
    providerDetails: PropTypes.object,
};

const mapStateToProps = state => {
    return {
        providerDetails: state.providerDetails,
    };
};

export default showIfLocation(connect(mapStateToProps)(Paired));
