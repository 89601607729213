import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import cart from 'actions/cartActions';
import {CartGridRowData, CartGridEmptyRow} from 'components/Cart/CartGridRow';
import {getCurrency} from 'reducers';

class CartGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            qtys: {},
        };
        this.onQtyChange = this.onQtyChange.bind(this);
    }
    onQtyChange(id, qty) {
        const qtys = {...this.state.qtys};
        qtys[id] = qty;
        this.setState({
            qtys,
        });
    }
    render() {
        const cart = this.props.cart;
        const onUpdateCartClick = this.props.onUpdateCartClick;
        return (
            <section className="Cart-grid">
                <table className="Cart-table">
                    <thead>
                        <tr>
                            <th className="Cart-table-thumb">Items in cart</th>
                            <th className="Cart-table-desc"></th>
                            <th className="Cart-table-qty">Qty</th>
                            <th className="Cart-table-unit">Unit Price</th>
                            <th className="Cart-table-total">Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cart.length ? cart.map(
                            item => (
                                <CartGridRowData
                                    key={item.id}
                                    item={item}
                                    testPricesEnabled={this.props.testPricesEnabled}
                                    currency={this.props.currency}
                                    onQtyChange={this.onQtyChange}
                                />
                            ),
                        ) : (
                            <CartGridEmptyRow key={0} />
                        )}
                    </tbody>
                </table>
                {cart.length ? (
                    <div className="text-right">
                        <button
                            onClick={() => onUpdateCartClick(this.state.qtys)}
                            className="Cart-update">
                            Update Cart
                        </button>
                    </div>
                ) : ''}
            </section>
        );
    }
}

CartGrid.propTypes = {
    cart: PropTypes.array.isRequired,
    onUpdateCartClick: PropTypes.func,
    currency: PropTypes.string.isRequired,
    testPricesEnabled: PropTypes.bool.isRequired,
};

CartGrid.defaultProps = {
    onUpdateCartClick: () => {},
};

const mapStateToProps = state => {
    return {
        cart: state.cart,
        testPricesEnabled: state.configMenu.customDestination.testPricesEnabled,
        currency: getCurrency(state),
    };
};

const mapDispatchToProps = dispatch => {
    return {
        onUpdateCartClick: qtys => {
            const newVals = {};
            for (const id in qtys) {
                if ({}.hasOwnProperty.call(qtys, id)) {
                    newVals[id] = qtys[id] && qtys[id];
                }
            }
            dispatch(cart.setQuantities(newVals));
        },
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartGrid);
