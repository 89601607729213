import {combineReducers} from 'redux';
import cart from 'reducers/cartReducer';
import configMenu from 'reducers/configMenuReducer';
import vrp from 'reducers/vrpReducer';

const transferError = (state = '', action) => {
    switch (action.type) {
        case 'TRANSFER_ERROR':
            return action.errorMessage;
        default:
            return state;
    }
};

const clientName = (state = 'southside', action) => {
    switch (action.type) {
        case 'SET_DEMO_CLIENT_NAME':
            return action.name;
        default:
            return state;
    }
};

const providerDetails = (state = {}, action) => {
    switch (action.type) {
        case 'SET_PROVIDER_DETAILS':
            return {...state, id: action.details.id, status: action.details.status};
        default:
            return state;
    }
};

const app = combineReducers({
    cart,
    configMenu,
    vrp,
    transferError,
    clientName,
    providerDetails,
});

export default app;

export const getCurrency = state => {
    return (state.configMenu.customDestination)
        ? state.configMenu.customDestination.currency
        : state.configMenu.currency;
};
