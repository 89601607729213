import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {
    createBulkTransfers,
    formatCurrentDate,
    formatCustomDestination,
    formatSourceAccount,
    getSubtotal,
    isFrequencyPresent,
    regexFreqExmpls,
    isValidRegex,
    validateAccount,
} from 'util.js';
import TokenEnablerButton from 'components/TokenEnablerButton';
import {FormattedNumber} from 'react-intl';
import {connect} from 'react-redux';
import {withHistory} from 'SimpleHistory';
import actionCreators from 'actions/cartActions';
import {bindActionCreators} from 'redux';
import {
    accountSchemes,
    BULK_TRANSFER,
    CONFIRM_FUNDS,
    currencySymbols,
    destinations,
    frequencies,
    FUTURE_DATED,
    merchantUrl,
    PAY_BY_BANK,
    STANDING_ORDER,
} from 'config/constants';
import './CartTotals.css';
import {getCurrency} from 'reducers';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import TextInput from '@token-io/lib-web-components/src/Components/TextInput';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import {getOptionsFromList} from '../../actions';
import {CLIENTS_URL_MAPPINGS} from '../../config/constants';
import {formatUrl} from '../../util';

const CartTotals = ({
    cart,
    advanced,
    bank,
    method,
    country,
    currency,
    webAppEnabled,
    customDestination,
    actions,
    url,
    history,
    source,
    betaVersionEnabled,
    memberType,
    clientName,
    options,
    cafEnabled,
    credentials,
    remittanceReference,
    webappInIframe,
    webappV2Modal,
}) => {
    const [option, setOption] = useState(options[0]);
    useEffect(() => setOption(option), [option]);
    const [extra, setExtra] = useState({});
    const [frequency, setFrequency] = useState(frequencies[0]);
    const [accountType, setAccountType] = useState('');
    const destination = advanced
        ? formatCustomDestination(customDestination.scheme, customDestination.account, memberType)
        : destinations[bank][method][country][currency];
    const sourceCountry = source.sourceCountry;
    const sourceAccount = (advanced
        && !(Object.keys(source.account).length === 0 && source.account.constructor === Object))
        ? formatSourceAccount(source.scheme, source.account, source.bankId, customDestination.scheme)
        : {bankId: source.bankId && source.bankId || ''};
    let isReady = (webAppEnabled || (!webAppEnabled && sourceAccount.bankId))
        && (!advanced || validateAccount(customDestination.scheme, customDestination.account))
        && (Object.keys(sourceAccount).length > 0);
    let err = false;
    if (option.code === 'standingOrder') {
        isReady = isReady && (extra.startDate !== '' && !err) && sourceAccount?.bankId !== '';
    } else if (option.code === 'futureDated') {
        isReady = isReady && (extra.executionDate !== '' || !extra.executionDate && !err);
    }
    const subtotal = parseFloat(getSubtotal(cart, customDestination.testPricesEnabled).toFixed(2));
    let bulkTransfers;
    if (option.code === 'bulkTransfer') {
        bulkTransfers = createBulkTransfers(cart, customDestination.testPricesEnabled, destination, currency);
    }
    const onChange = opt => {
        if (opt.code !== option.code)
            setOption(opt);
    };
    const displayRegexInput = destination?.account?.fasterPayments && isFrequencyPresent(extra.frequency);
    extra.frequencyRegex = displayRegexInput ? extra.frequencyRegex : undefined;
    const displayRegexExmpls = regexFreqExmpls(extra.frequency);
    let regexError = false;
    const renderSwitch = opt => {
        switch (opt.code) {
            case STANDING_ORDER:
                if (!extra.frequency) {
                    setExtra({frequency});
                }
                if (formatCurrentDate() > extra.startDate || formatCurrentDate() > extra.endDate) {
                    err = true;
                }
                if (!extra.startDate || err) {
                    isReady = false;
                }
                if (extra?.frequencyRegex && !isValidRegex(extra.frequency, extra.frequencyRegex)) {
                    isReady = false;
                    regexError = true;
                }
                return (
                    <div style={{overflow: 'hidden'}}>
                        <StaticDataProvider
                            idKey={'code'} titleKey={'code'} dataKey={'key'}
                            source={() => frequencies}
                            useCustomData
                            customData={frequencies}
                            value={extra.frequency ? extra.frequency : frequencies[0]}
                            customSetValue={async (value, {doneCallback}) => {
                                await setExtra({
                                    ...extra,
                                    frequency: value,
                                });
                                doneCallback();
                            }}
                            withPointer>
                            <Dropdown
                                className={'frequencySelector'}
                                rowClass={'frequencySelector-item'}
                                allowDeselect />
                        </StaticDataProvider>
                        <div>
                            Start Date: <input style={{float: 'right'}} type={'date'} value={extra.startDate || ''}
                                onChange={event => {
                                    if (extra.startDate !== event.target.value) {
                                        setExtra({
                                            ...extra,
                                            startDate: event.target.value,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <br />
                        <div>
                            End Date (optional): <input style={{float: 'right'}} type={'date'} name={'endDate'}
                                value={extra.endDate || ''}
                                onChange={event => {
                                    if (extra.endDate !== event.target.value) {
                                        setExtra({
                                            ...extra,
                                            endDate: event.target.value,
                                        });
                                    }
                                }}
                            />
                        </div>
                        <br />
                        { displayRegexInput && (
                            <div>
                                <span className={'FrequencyRegex-label'}>
                                    Regular Expression For Frequency:
                                </span>
                                <input className={'FrequencyRegex-input'} type='text' name={'FrequencyRegEx'}
                                    value={extra.frequencyRegex || ''}
                                    onChange={event => {
                                        if (extra.frequencyRegex !== event.target.value) {
                                            setExtra({
                                                ...extra,
                                                frequencyRegex: event.target.value,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        )}
                        {err ?
                            <div style={{color: 'red'}}>
                                Please enter a valid date
                            </div> : null}
                        {regexError ?
                            <div style={{color: 'red', float: 'left'}}>
                                <span>
                                    Please enter a valid regular expression matching below patterns. For example:
                                </span>
                                {displayRegexExmpls.map((element,index) => {
                                    return (
                                        <div key={'Regex-Empl' + index}>
                                            <div key={index} style={{float: 'left'}}>
                                                {index + 1 + '. ' + element.join(', ') }
                                            </div>
                                            <br />
                                        </div>
                                    );
                                })}
                            </div> : null}
                    </div>
                );
            case FUTURE_DATED:
                if (formatCurrentDate() > extra.executionDate) {
                    err = true;
                }
                if (!extra.executionDate || err) {
                    isReady = false;
                }
                return (
                    <div>
                        Execution Date: <input required type={'date'} min={formatCurrentDate()}
                            onChange={event => {
                                if (extra.executionDate !== event.target.value) {
                                    setExtra({
                                        executionDate: event.target.value,
                                    });
                                }
                            }
                            }
                        />
                        {err ?
                            <div style={{color: 'red'}}>
                                Please enter a valid date
                            </div> : null}
                    </div>
                );
            case CONFIRM_FUNDS:
                if (!extra.bankAccount || !extra.bankId) {
                    isReady = false;
                }
                return (
                    <div>
                        Bank ID: <TextInput
                            onChange={value => {
                                if (extra.bankId !== value) {
                                    setExtra({
                                        ...extra,
                                        bankId: value,
                                    });
                                }
                            }
                            }
                        />
                        Account Type:
                        <StaticDataProvider
                            idKey={'code'} titleKey={'code'} dataKey={'key'}
                            source={() => accountSchemes}
                            useCustomData
                            customData={accountSchemes}
                            value={accountType}
                            customSetValue={async (value, {doneCallback}) => {
                                await setAccountType(value);
                                doneCallback();
                            }}
                            withPointer>
                            <Dropdown
                                className={'accountTypeSelector'}
                                rowClass={'accountTypeSelector-item'}
                                allowDeselect />
                        </StaticDataProvider>
                        <br />{(accountType.code === 'iban' || accountType.code === 'domestic') ?
                            <div>
                            Account Number: <TextInput
                                    onChange={value => {
                                        if (accountType.code === 'iban') {
                                            setExtra({
                                                ...extra,
                                                bankAccount: {
                                                    iban: {
                                                        iban: value,
                                                    },
                                                },
                                            });
                                        } else if (accountType.code === 'domestic') {
                                            setExtra({
                                                ...extra,
                                                bankAccount: {
                                                    domestic: {
                                                        ...extra?.bankAccount?.domestic,
                                                        accountNumber: value,
                                                    },
                                                },
                                            });
                                        }
                                    }
                                    }
                                />
                                {accountType.code === 'domestic' ?
                                    <div>
                                    Bank Code:
                                        <TextInput
                                            onChange={value => {
                                                setExtra({
                                                    ...extra,
                                                    bankAccount: {
                                                        domestic: {
                                                            ...extra?.bankAccount?.domestic,
                                                            bankCode: value,
                                                        },
                                                    },
                                                });
                                            }}
                                        />
                                    </div> : null}
                            </div> :
                            <div style={{color: 'red'}}>
                            Please select an account type.
                            </div>}
                    </div>
                );
            case BULK_TRANSFER:
                return null;
            default:
            case PAY_BY_BANK:
                return null;
        }
    };
    return (
        <aside className="Totals">
            <div>
                <dl className="Totals-subTotal">
                    <dt>Subtotal</dt>
                    <dd>{currencySymbols[currency]} <FormattedNumber
                        value={subtotal}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2} />
                    </dd>
                </dl>
                <dl className="Totals-grandTotal">
                    <dt>Grand Total</dt>
                    <dd>{currencySymbols[currency]} <FormattedNumber
                        value={subtotal}
                        minimumFractionDigits={2}
                        maximumFractionDigits={2} />
                    </dd>
                </dl>
                {cart.length ? (
                    <div>
                        <button
                            className="Totals-stdCheckout"
                            onClick={() => {
                                history.push(formatUrl(clientName, '/cart/checkout'));
                            }}>
                            Checkout
                        </button>
                        <StaticDataProvider
                            idKey={'code'} titleKey={'code'} dataKey={'key'}
                            source={() => options}
                            useCustomData
                            customData={options}
                            value={option}
                            customSetValue={async (value, {doneCallback}) => {
                                setExtra({});
                                await onChange(value);
                                doneCallback();
                            }}
                            withPointer>
                            <Dropdown
                                className={'optionSelector'}
                                rowClass={'optionSelector-item'}
                                allowDeselect />
                        </StaticDataProvider>
                        {renderSwitch(option)}
                        {isReady && <TokenEnablerButton
                            amount={subtotal}
                            destination={destination}
                            currency={currency}
                            webAppEnabled={webAppEnabled}
                            option={option}
                            extra={extra}
                            url={url}
                            devKey={customDestination && customDestination.devKey}
                            isReady={isReady}
                            onError={err => actions.setTransferError(err)}
                            text={option.key}
                            source={sourceAccount}
                            bulkTransfers={bulkTransfers}
                            sourceCountry = {sourceCountry}
                            betaVersionEnabled={betaVersionEnabled}
                            memberType={memberType}
                            cafEnabled={cafEnabled}
                            remittanceReference={remittanceReference}
                            credentials={credentials}
                            webappInIframe={webappInIframe}
                            webappV2Modal={webappV2Modal} />}
                    </div>
                ) : ''}
            </div>
        </aside>
    );
};

CartTotals.propTypes = {
    cart: PropTypes.array.isRequired,
    advanced: PropTypes.bool.isRequired,
    bank: PropTypes.string.isRequired,
    method: PropTypes.string.isRequired,
    country: PropTypes.string.isRequired,
    currency: PropTypes.string.isRequired,
    webAppEnabled: PropTypes.bool.isRequired,
    customDestination: PropTypes.object,
    actions: PropTypes.object.isRequired,
    url: PropTypes.string.isRequired,
    devKey: PropTypes.string,
    history: PropTypes.object,
    source: PropTypes.object,
    betaVersionEnabled: PropTypes.bool,
    memberType: PropTypes.string,
    clientName: PropTypes.string,
    options: PropTypes.array,
    cafEnabled: PropTypes.bool,
    credentials: PropTypes.object,
    remittanceReference: PropTypes.string,
    webappInIframe: PropTypes.bool,
    webappV2Modal: PropTypes.bool,
};

const mapStateToProps = state => {
    return {
        cart: state.cart,
        advanced: state.configMenu.advanced,
        bank: state.configMenu.bank,
        method: state.configMenu.method,
        country: state.configMenu.country,
        webAppEnabled: state.configMenu.webAppEnabled,
        currency: getCurrency(state),
        customDestination: state.configMenu.customDestination,
        url: merchantUrl,
        source: state.configMenu.source,
        betaVersionEnabled: state.configMenu.betaVersion,
        webappInIframe: state.configMenu.webappInIframe,
        memberType: state.configMenu.memberType,
        clientName: state.clientName,
        options: getOptionsFromList(state.clientName),
        cafEnabled: state.configMenu.isCafEnabled,
        credentials: state.configMenu.source.credentials,
        remittanceReference: state.configMenu.remittanceReference,
        webappV2Modal: state.configMenu.webappV2Modal,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        actions: bindActionCreators(actionCreators, dispatch),
    };
};

export default withHistory(connect(
    mapStateToProps,
    mapDispatchToProps,
)(CartTotals));
