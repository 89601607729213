import React, { useCallback, useEffect, useState } from 'react';
import useLocalStorage from 'useLocalStorage';
import { memberInfo, VRP_TYPE_SWEEPING } from 'config/constants.js';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

//Wrapper Component
const VRPSetupFormDefaults = props => {
    const {
        memberId,
        vrpType = VRP_TYPE_SWEEPING,
        children,
        ...rest
    } = props;
    const [value, setValue] = useLocalStorage('vrp-setup-form-data', {});
    const [defaults, setDefaults] = useState({});

    useEffect(() => {
        const defaultValue = value?.[vrpType]?.[memberId]?.formData;
        if (defaultValue) {
            setDefaults(defaultValue);
        } else {
            setDefaults({});
        }
    }, [vrpType, memberId]);

    const storeVRPSetupFormValues = useCallback(data => {
        const finalData = {
            ...value,
            [vrpType]: {
                ...value?.[vrpType],
                [memberId]: {
                    ...value?.[vrpType]?.[memberId],
                    formData: {
                        ...value?.[vrpType]?.[memberId]?.formData,
                        ...data,
                    },
                },
            },
        };
        setValue(finalData);
    }, [vrpType, memberId]);

    return (
        <>
            {React.cloneElement(children, { defaults, storeVRPSetupFormValues, memberId, ...rest })}
        </>
    );
};

VRPSetupFormDefaults.propTypes = {
    memberId: PropTypes.string,
    vrpType: PropTypes.string,
    children: PropTypes.any,
};

VRPSetupFormDefaults.defaultProps = {
    vrpType: VRP_TYPE_SWEEPING,
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;

    return {
        memberId: memberInfo[clientName][memberType]['id'],
    };
};

export default connect(mapStateToProps)(VRPSetupFormDefaults);
