import React from 'react';
import PropTypes from 'prop-types';
import {IntlProvider} from 'react-intl';
import {showIfLocation, Link} from 'SimpleHistory';
import {connect} from 'react-redux';
import './Subscription.css';

class Subscription extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const buttonText = this.props.buttonText || 'Setup Now';

        return (
            <IntlProvider locale="en">
                <div className='Subscription'>
                    <div className='Subscription-body'>
                        <div className='Subscription-header-container'>
                            <div className='Subscription-header'>
                                Subscription Services
                            </div>
                        </div>
                        <div className='Subscription-content'>
                            <div>Token supports the ability to setup and pay subscription services using open banking variable recurring payments securely in an easy and efficient way.</div>
                        </div>
                        <div className='Subscription-button-container'>
                            <Link
                                to="/services/nonSweeping/autopay/setup"
                                className="Subscription-button">
                                {buttonText}
                            </Link>
                        </div>
                    </div>
                </div>
            </IntlProvider>
        );
    }
}

Subscription.propTypes = {
    buttonText: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    return {
        buttonText: ownProps.buttonText,
    };
};

export default showIfLocation(connect(mapStateToProps)(Subscription));
