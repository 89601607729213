import React, { useCallback, useEffect, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import './VRPPaymentAccountDetails.css';
import {withHistory} from 'SimpleHistory';
import VRPSetupFormDefaults from '../../VRPSetupFormDefaults';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import AsyncDataProvider from '@token-io/lib-web-components/src/Components/Data/AsyncDataProvider';
import Search from '@token-io/lib-web-components/src/Components/Search';
import { ROW_SIZE_STANDARD, ROW_SIZE_LARGE } from '@token-io/lib-web-components/src/Components/List/Abstract';
import { formatDataList } from '@token-io/lib-web-components/src/Util';
import { LOCAL_INSTRUMENTS, TOKEN_API_URL, memberInfo, VRP_TYPE_SWEEPING } from 'config/constants.js';
import { formatAsKeyValuePair, fetchBanksOrCountries, formatCountries } from 'util.js';
import { VRPSetupContext } from './Sweeping/VRPSetupForm.js';
import { SubscriptionSetupContext } from './NonSweeping/VRPSetupForm.js';

const localInstruments = formatAsKeyValuePair(Object.keys(LOCAL_INSTRUMENTS));
const formatLocalInstrument = defaultLocalInstrument => {
    return localInstruments.find(
        obj => (
            defaultLocalInstrument
                ? LOCAL_INSTRUMENTS[obj.value] === defaultLocalInstrument
                : obj.value === 'DOMESTIC'
        ),
    );
};

const VRPPaymentAccountDetails = ({ devKey, memberId, webAppEnabled, defaults, vrpType }) => {
    // Defaults
    const {
        country: defaultCountryCode = 'GB',
        localInstrument: defaultLocalInstrument,
        defaultSource = {
            iban: defaults?.source?.iban || '',
            bic: defaults?.source?.bic || '',
            accountNumber: defaults?.source?.accountNumber || '',
            sortCode: defaults?.source?.sortCode || '',
        },
    } = defaults;

    const { setSourceDetailsReady, setPaymentSourceDetails } = useContext(
        vrpType === VRP_TYPE_SWEEPING ? VRPSetupContext : SubscriptionSetupContext);

    const searchRef = useRef(null);
    const localInstruments = formatAsKeyValuePair(Object.keys(LOCAL_INSTRUMENTS));
    const defaultPaging = {
        page: 1,
        perPage: 25,
        pageCount: 1,
    };

    const [localInstrument, setLocalInstrument] = useState(formatLocalInstrument(defaultLocalInstrument));
    const [source, setSource] = useState(defaultSource);
    const [countries, setCountries] = useState(null);
    const [banks, setBanks] = useState([]);
    const [paging, setPaging] = useState(defaultPaging);
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [searchKeyWord, setSearchKeyWord] = useState('');
    const [loadingMore, setLoadingMore] = useState(false);
    const [selectedBank, setSelectedBank] = useState(null);
    const [bankSearchInputValue, setBankSearchInputValue] = useState('');

    // Populates default values
    useEffect(
        () => {
            setSource(defaultSource);
            setLocalInstrument(formatLocalInstrument(defaultLocalInstrument));
        },
        [defaults],
    );

    const handleInstrumentChange = value => {
        setLocalInstrument(value);
        if (value === LOCAL_INSTRUMENTS['IBAN']) {
            setPaymentSourceDetails({ currency: 'EUR' });
        } else if (value === LOCAL_INSTRUMENTS['DOMESTIC']) {
            setPaymentSourceDetails({ currency: 'GBP' });
        }
    };

    const handleCountries = countries => {
        setCountries(countries);
        setBanks([]);
        setPaging(defaultPaging);
    };

    const fetchBanks = useCallback(
        async ({
            nextPage = 1,
            searchKeyWord,
        }) => {
            try {
                const {page, perPage} = paging;
                const actualPage = nextPage || page;
                const extraQuery = searchKeyWord
                    && `&search=${searchKeyWord}`
                    || '';

                const res = await fetchBanksOrCountries(
                    {
                        url: TOKEN_API_URL
                            + `/banks?country=${selectedCountry?.code}`
                            + `&page=${actualPage}&perPage=${perPage}`
                            + `&memberId=${memberId}${extraQuery}`,
                        devKey,
                    });
                if (res.status === 200) {
                    const banksRes = res.data;
                    const { data: newBanks, paging } = formatDataList(banksRes, {
                        fetchDataKey: 'banks',
                        fetchPagingKey: 'paging',
                        idKey: 'id',
                        titleKey: 'name',
                        dataKey: 'identifier',
                    });
                    if (nextPage === 1) {
                        setBanks(newBanks);
                    } else {
                        setBanks([...banks, ...newBanks]);
                    }
                    setPaging(paging);
                }
                return { banks, paging };
            } catch (error) {
                console.error(error);
            }
        },
    );

    const fetchCountries = useCallback(
        async () => {
            try {
                const res = await fetchBanksOrCountries({ url: TOKEN_API_URL + `/banks/countries?memberId=${memberId}`, devKey});
                if (res.status === 200) {
                    const countries = res.data?.countries;
                    handleCountries(countries);
                }
            } catch (e) {
                console.error(e);
            }
        },
        [memberId, devKey],
    );

    useEffect(
        () => {
            if (selectedCountry) {
                setSelectedCountry(null);
                if (selectedBank) {
                    setSelectedBank('');
                    setBankSearchInputValue('');
                }
            }
            fetchCountries();
        },
        [memberId, devKey],
    );

    // Set default country to 'GB' if it's configured
    useEffect(
        () => {
            const countryCode = defaultCountryCode?.toUpperCase();
            if (countries?.length > 0 && countryCode) {
                const defaultCountry = countries?.includes(countryCode)
                        && formatCountries([countryCode]);
                setSelectedCountry(defaultCountry?.[0]);
            }
        },
        [countries],
    );

    useEffect(
        () => {
            if (selectedCountry) {
                fetchBanks({});
            }
        },
        [selectedCountry],
    );

    useEffect(
        () => {
            const selectedCountryCode = selectedCountry?.code;
            const sourceBankId = selectedBank?.id;
            const instrument = LOCAL_INSTRUMENTS[localInstrument?.value];
            const currency = instrument !== LOCAL_INSTRUMENTS.IBAN ? 'GBP' : 'EUR';
            const src = instrument !== LOCAL_INSTRUMENTS.IBAN
                ? {
                    accountNumber: source?.accountNumber,
                    sortCode: source?.sortCode,
                } : {
                    iban: source?.iban,
                    bic: source?.bic,
                };
            if (
                selectedCountryCode &&
                instrument
            ) {
                const isSrcPresent = Object.values(src).some(x => Boolean(x));
                const condition1 = !Object.values(src).some(x => !x) && Boolean(sourceBankId);

                const isReady = (isSrcPresent || !webAppEnabled)
                    ? condition1 : true;

                setPaymentSourceDetails({
                    country: selectedCountryCode,
                    localInstrument: instrument,
                    ...(isSrcPresent && { source: src }),
                    currency,
                    sourceBankId,
                });
                setSourceDetailsReady(isReady);
            } else {
                setPaymentSourceDetails({ currency });
                setSourceDetailsReady(false);
            }
        },
        [selectedCountry, selectedBank, source, localInstrument, webAppEnabled],
    );

    const {page, perPage, pageCount} = paging;

    return (
        <>
            <div className='VRPSetup-sub-title'>
                Payment Account details
            </div>
            <div className='VRPSetupForm-row'>
                <div className='VRPSetupForm-title'>
                    Account Type:
                </div>
                <div className='VRPSetupForm-detail'>
                    <StaticDataProvider
                        source={localInstruments}
                        idKey={'id'}
                        titleKey={'id'}
                        dataKey={'value'}
                        onChange={handleInstrumentChange}
                        value={localInstrument}
                        withPointer>
                        <Dropdown
                            className={'VRPSetupForm-localInstrument-dropdown'}
                            size={ROW_SIZE_STANDARD}
                        />
                    </StaticDataProvider>
                </div>
            </div>
            {
                localInstrument && LOCAL_INSTRUMENTS[localInstrument?.value] === LOCAL_INSTRUMENTS.IBAN
                    ? (
                        <>
                            <div className='VRPSetupForm-row'>
                                <div className='VRPSetupForm-title'>
                                    IBAN:
                                </div>
                                <div className='VRPSetupForm-detail'>
                                    <input
                                        name='Vrp-iban'
                                        onChange={e => {
                                            setSource({
                                                ...source,
                                                'iban': e.target.value,
                                            });
                                        }}
                                        value={source?.iban}
                                    />
                                </div>
                            </div>
                            <div className='VRPSetupForm-row'>
                                <div className='VRPSetupForm-title'>
                                    Bic:
                                </div>
                                <div className='VRPSetupForm-detail'>
                                    <input
                                        name='Vrp-bic'
                                        onChange={e => {
                                            setSource({
                                                ...source,
                                                'bic': e.target.value,
                                            });
                                        }}
                                        value={source?.bic}
                                    />
                                </div>
                            </div>
                        </>
                    )
                    : (
                        <>
                            <div className='VRPSetupForm-row'>
                                <div className='VRPSetupForm-title'>
                                    Account Number:
                                </div>
                                <div className='VRPSetupForm-detail'>
                                    <input
                                        name='Vrp-accountNumber'
                                        onChange={e => {
                                            setSource({
                                                ...source,
                                                'accountNumber': e.target.value,
                                            });
                                        }}
                                        value={source?.accountNumber}
                                    />
                                </div>
                            </div>
                            <div className='VRPSetupForm-row'>
                                <div className='VRPSetupForm-title'>
                                    Sort Code:
                                </div>
                                <div className='VRPSetupForm-detail'>
                                    <input
                                        name='Vrp-sortCode'
                                        onChange={e => {
                                            setSource({
                                                ...source,
                                                'sortCode': e.target.value,
                                            });
                                        }}
                                        value={source?.sortCode}
                                    />
                                </div>
                            </div>
                        </>
                    )
            }
            {
                countries &&
                (
                    <div className='VRPSetupForm-row'>
                        <div className='VRPSetupForm-title'>
                            Country:
                        </div>
                        <div className='VRPSetupForm-detail'>
                            <StaticDataProvider
                                source={formatCountries(countries)}
                                idKey={'code'}
                                titleKey={'code'}
                                dataKey={'key'}
                                onChange={setSelectedCountry}
                                value={selectedCountry}
                                withPointer>
                                <Dropdown
                                    className={'VRPSetupForm-country-dropdown'}
                                    placeholder={'Select your country'}
                                    allowDeselect
                                />
                            </StaticDataProvider>
                        </div>
                    </div>
                )
            }
            {
                selectedCountry && (
                    <div className='VRPSetupForm-row'>
                        <div className='VRPSetupForm-title'>
                            My Bank:
                        </div>
                        <div className='VRPSetupForm-detail'>
                            <AsyncDataProvider
                                page={page}
                                pageSize={perPage}
                                source={async options => {
                                    const searching = options.search !== searchKeyWord;
                                    const nextPage =
                                        (searching && 1) ||
                                        (page && page + 1) ||
                                        2;
                                    if (
                                        !searching &&
                                        (loadingMore || page >= pageCount)
                                    ) {
                                        return;
                                    }
                                    setLoadingMore(true);
                                    setSearchKeyWord(options.search);

                                    await fetchBanks(
                                        {
                                            nextPage,
                                            searchKeyWord: options.search,
                                        },
                                    );
                                    setLoadingMore(false);
                                }}
                                fetchOnMount={false}
                                useCustomData
                                customData={banks}
                                customLoading={loadingMore}
                                customSetValue={async (
                                    value,
                                    { doneCallback },
                                ) => {
                                    const bank = {
                                        ...value.rawData,
                                        selectedCountry: selectedCountry.code.toUpperCase(),
                                    };
                                    setSelectedBank(bank);
                                    doneCallback();
                                }}
                                value={selectedBank}
                                withPointer>
                                <Search
                                    ref={searchRef}
                                    id={'Bank-Selector-Input'}
                                    size={ROW_SIZE_LARGE}
                                    placeholder={'Select your bank'}
                                    className={'VRPSetupForm-detail-bank-search'}
                                    listClass={'VRPSetupForm-detail-bank-search-list'}
                                    rowClass={'VRPSetupForm-detail-bank-search-list-item'}
                                    customInputOnChange={(value, {doneCallback}) => {
                                        setSelectedBank(null);
                                        setBankSearchInputValue(value);
                                        doneCallback();
                                    }}
                                    customInputValue={selectedBank?.name || bankSearchInputValue}
                                />
                            </AsyncDataProvider>
                        </div>
                    </div>
                )
            }
        </>
    );
};

VRPPaymentAccountDetails.propTypes = {
    devKey: PropTypes.string,
    destination: PropTypes.object,
    memberType: PropTypes.string,
    clientName: PropTypes.string,
    memberId: PropTypes.string,
    webAppEnabled: PropTypes.bool,
    vrpType: PropTypes.string,
    defaults: PropTypes.object,
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;
    const memberId = memberInfo[clientName][memberType]['id'];

    return {
        webAppEnabled: state.configMenu.webAppEnabled,
        devKey: state.configMenu.customDestination.devKey,
        destination: state.configMenu.customDestination,
        memberType,
        clientName,
        memberId,
    };
};

const VRPSourceWithDefaults = props => {
    return (
        <VRPSetupFormDefaults vrpType={props.vrpType}>
            <VRPPaymentAccountDetails {...props} />
        </VRPSetupFormDefaults>
    );
};

VRPSourceWithDefaults.propTypes = {
    vrpType: PropTypes.string,
};

export default withHistory(connect(mapStateToProps)(VRPSourceWithDefaults));
