import { VRP_TYPES } from 'config/constants';
import { VRP_TYPE_SWEEPING } from '../../server/constants';

const vrp = ( state = {}, action ) => {
    switch (action.type) {
        case 'SET_VRP_CONSENT': {
            const { memberId, id, ...rest } = action.vrp;
            const { vrpType } = action.vrp;
            return {
                ...state,
                [vrpType]: {
                    ...state[vrpType],
                    vrpConsentList: {
                        ...state[vrpType]?.vrpConsentList,
                        [action.vrp.memberId]: {
                            ...state[vrpType]?.vrpConsentList && state[vrpType].vrpConsentList[action.vrp.memberId],
                            [action.vrp.id]: {
                                ...rest,
                                memberId: action.vrp.memberId,
                                updatedAt: Date.now(),
                                createdAt: Date.now(),
                            },
                        },
                    },
                },
            };
        }
        case 'SET_VRP': {
            let { consentId, memberId, vrpType } = action.payload;
            const { vrp, updatedAt, unHealthy } = action.payload;
            vrpType = vrpType || VRP_TYPE_SWEEPING;
            if (!consentId) {
                consentId = vrp.initiation.consentId;
            }
            if (!memberId) {
                memberId = vrp.memberId;
            }

            const { id, ...rest } = vrp || { id: null };
            return {
                ...state,
                [vrpType]: {
                    vrpConsentList: {
                        ...state[vrpType].vrpConsentList,
                        [memberId]: {
                            ...state[vrpType].vrpConsentList && state[vrpType].vrpConsentList[memberId],
                            [consentId]: {
                                ...state[vrpType].vrpConsentList && state[vrpType].vrpConsentList[memberId] && state[vrpType].vrpConsentList[memberId][consentId],
                                ...(id && {
                                    vrps: {
                                        ...state[vrpType].vrpConsentList && state[vrpType].vrpConsentList[memberId] && state[vrpType].vrpConsentList[memberId][consentId].vrps,
                                        [id]: {
                                            ...rest,
                                        },
                                    },
                                }),
                                ...(updatedAt && {updatedAt}),
                                ...(unHealthy && {unHealthy}),
                            },
                        },
                    },
                },
            };
        }
        case 'CLEAN_UP_VRPS': {
            for (const vrpType of VRP_TYPES) {
                const memberIds = state[vrpType] && state[vrpType].vrpConsentList
                && Object.keys(state[vrpType].vrpConsentList)
                || [];

                if (
                    memberIds.length > 0
                ) {
                    memberIds.map(memberId => {
                        Object.keys(state[vrpType].vrpConsentList[memberId])
                            .map(id => {
                                const vrpDetails = state[vrpType].vrpConsentList[memberId][id];
                                const currentSession = window.sessionStorage.getItem('session-id');
                                if (vrpDetails.sessionId !== currentSession) {
                                    delete state[vrpType].vrpConsentList[memberId][id];
                                }
                            });
                        if (
                            state[vrpType].vrpConsentList[memberId]
                            && Object.keys(state[vrpType].vrpConsentList[memberId])?.length === 0
                        ) {
                            delete state[vrpType].vrpConsentList[memberId];
                        }
                    });
                }
            }
            return { ...state };
        }
        case 'VRP_ERROR': {
            const error = action.errorMessage?.message;
            return { ...state, error };
        }
        case 'DELETE_VRP_CONSENT': {
            const vrpId = action.id;
            const vrpType = action.vrpType;
            const memberIds = state[vrpType] && state[vrpType].vrpConsentList
                && Object.keys(state[vrpType].vrpConsentList)
                || [];

            if (
                memberIds.length > 0
            ) {
                memberIds.some(memberId => {
                    if (
                        state[vrpType].vrpConsentList[memberId]
                        && state[vrpType].vrpConsentList[memberId][vrpId]
                    ) {
                        delete state[vrpType].vrpConsentList[memberId][vrpId];

                        if (
                            state[vrpType].vrpConsentList[memberId]
                            && Object.keys(state[vrpType].vrpConsentList[memberId])?.length === 0
                        ) {
                            delete state[vrpType].vrpConsentList[memberId];
                        }

                        return true;
                    }
                });
            }
            return { ...state };
        }
        default:
            return state;
    }
};

export default vrp;
