import React from 'react';
import {IntlProvider} from 'react-intl';
import {showIfLocation, Link} from 'SimpleHistory';
import './SubscriptionSetup.css';
import VRPSetupForm from './VRPSetupForm';

class SubscriptionSetup extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        return (
            <IntlProvider locale="en">
                <div className="Cart">
                    <nav className="Breadcrumbs">
                        <Link to="/">Home</Link>
                        <Link to="/services">Services</Link>
                        <Link to="/services/nonSweeping/autopay/setup" className="active">Subscription-setup</Link>
                    </nav>
                    <VRPSetupForm />
                </div>
            </IntlProvider>
        );
    }
}

export default showIfLocation(SubscriptionSetup);
