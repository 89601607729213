import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {CSSTransition, TransitionGroup} from 'react-transition-group';
import './MenuDrawer.css';

class MenuDrawer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
        };
    }
    openDrawer() {
        if (this.state.open) {
            return;
        }
        this.setState({
            open: true,
        });
    }
    closeDrawer() {
        if (!this.state.open) {
            return;
        }
        this.setState({
            open: false,
        });
    }
    render() {
        const {children, iconColor} = this.props;
        const drawer = [
            (this.state.open ? (
                <CSSTransition
                    classNames="MenuDrawer-transition"
                    timeout={{enter: 350, exit: 350}}>
                    <div key="MenuDrawer" className="MenuDrawer-wrapper">
                        <div className="MenuDrawer-bg" onClick={e => {
                            e.preventDefault();
                            this.closeDrawer();
                        }} />
                        <div className="MenuDrawer-panel" onClick={e => {
                            const data = e.target.dataset;
                            if (data.close) {
                                this.closeDrawer();
                            }
                        }}>
                            {children}
                        </div>
                    </div>
                </CSSTransition>
            ) : null),
        ];
        return (
            <div className="MenuDrawer">
                <div className="MenuDrawer-button">
                    <a href="#" onClick={e => {
                        e.preventDefault();
                        this.openDrawer();
                    }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16">
                            <path fill={iconColor} d="M0 0h17v2H0V0zm0 7h17v2H0V7zm0 7h17v2H0v-2z" />
                        </svg>
                    </a>
                </div>
                <TransitionGroup>
                    {drawer}
                </TransitionGroup>
            </div>
        );
    }
}

MenuDrawer.propTypes = {
    children: PropTypes.node,
    iconColor: PropTypes.string,
};

MenuDrawer.defaultProps = {
    iconColor: '#000',
};

export default MenuDrawer;
