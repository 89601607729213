const vrp = {
    setVRPConsent: vrp =>
        ({
            type: 'SET_VRP_CONSENT',
            vrp,
        }),
    cleanUpAutoVRPSetups: () =>
        ({
            type: 'CLEAN_UP_VRPS',
        }),
    setVrp: payload =>
        ({
            type: 'SET_VRP',
            payload,
        }),
    deleteVRPConsent: (id, vrpType) =>
        ({
            type: 'DELETE_VRP_CONSENT',
            id,
            vrpType,
        }),
    setVrpError: error =>
        ({
            type: 'VRP_ERROR',
            errorMessage: error,
        }),
};

export default vrp;
