import React, { useEffect, useLayoutEffect } from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import vrpActions from 'actions/vrp.js';
import axios from 'axios';
import { VRP_PAYMENT_STATUS, VRP_TYPES} from 'config/constants.js';

const merchantInstance = axios.create({
    baseURL: window.location.origin,
});

const VRP_TIME_INTERVAL = 60000; // 1 min
const VRP_TIMEOUT = 600000; // 10 mins
const VRP_AMOUNT = 1;

const VRPCronJob = props => {
    const {
        autoPayList,
        children,
        setVrp,
        cleanUpAutoVRPSetups,
    } = props;

    const interval = 6000;
    let intervalId;

    const job = async data => {
        const handleFailure = async () => {
            await setVrp({
                consentId: data.id,
                memberId: data.memberId,
                unHealthy: true,
                updatedAt: data.updatedAt,
                vrpType: data.vrpType,
            });
        };

        try {
            const res = await merchantInstance(
                {
                    method: 'post',
                    url: '/vrp',
                    data: {
                        amount: VRP_AMOUNT,
                        currency: data.currency,
                        remittanceInformationPrimary: data.remittanceInformationPrimary,
                        consentId: data.id,
                        memberId: data.memberId,
                    },
                },
            );
            if (res.status === 200) {
                if (
                    res.data.status === VRP_PAYMENT_STATUS.INITIATION_PROCESSING
                        || res.data.status === VRP_PAYMENT_STATUS.INITIATION_COMPLETED
                ) {
                    await setVrp({
                        consentId: data.id,
                        memberId: data.memberId,
                        vrp: res.data,
                        updatedAt: data.updatedAt,
                        vrpType: data.vrpType,
                    });
                } else {
                    await setVrp({
                        consentId: data.id,
                        memberId: data.memberId,
                        unHealthy: true,
                        updatedAt: data.updatedAt,
                        vrpType: data.vrpType,
                    });
                }
            } else {
                await handleFailure();
            }
        } catch (e) {
            await handleFailure();
        }
    };

    useEffect(
        () => {
            cleanUpAutoVRPSetups();
        },
        [],
    );

    useLayoutEffect(
        () => {
            const list = autoPayList
                && Object.keys(autoPayList).map(k => { return { id: k, ...autoPayList[k] }; })
                || [];

            if (intervalId) {
                clearInterval(intervalId);
            }

            intervalId = setInterval(
                () => {
                    if (list.length > 0) {
                        const currentTime = Date.now();
                        list.map(
                            obj => {
                                if (
                                    (currentTime - obj.updatedAt) >= VRP_TIME_INTERVAL // 1 min
                                    && (currentTime - obj.createdAt) <= VRP_TIMEOUT // 10 mins
                                    && !obj?.unHealthy
                                ) {
                                    job({
                                        ...obj,
                                        updatedAt: currentTime,
                                    });
                                }
                            },
                        );
                    }
                },
                interval,
            );

            return () => {
                clearInterval(intervalId);
            };
        },
        [autoPayList],
    );

    return (
        <>
            {React.cloneElement(children)}
        </>
    );
};

VRPCronJob.propTypes = {
    autoPayList: PropTypes.object,
    children: PropTypes.any,
    setVrp: PropTypes.func,
    cleanUpAutoVRPSetups: PropTypes.func,
};

const mapStateToProps = state => {
    let autoPayList = {};
    for (const vrpType of VRP_TYPES) {
        if (state.vrp?.[vrpType]?.vrpConsentList) {
            autoPayList = { ...autoPayList, ...Object.keys(state.vrp?.[vrpType]?.vrpConsentList)
                .reduce(
                    (prev, curr) => {
                        return {
                            ...prev,
                            ...state.vrp?.[vrpType]?.vrpConsentList[curr],
                        };
                    },
                    {},
                ),
            };
        }
    }
    return {
        autoPayList,
    };
};

const mapDispatchToProps = {
    cleanUpAutoVRPSetups: vrpActions.cleanUpAutoVRPSetups,
    setVrp: vrpActions.setVrp,
};

export default connect(mapStateToProps, mapDispatchToProps)(VRPCronJob);
