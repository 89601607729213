import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { showIfLocation, Link } from 'SimpleHistory';
import {IntlProvider} from 'react-intl';
import { VRP_PAYMENT_STATUS, VRP_TYPE_SWEEPING } from 'config/constants.js';
import {connect} from 'react-redux';
import './PayHistory.css';
import { parseParamsFromUrl } from 'util.js';
import HistoryIcon2 from '../../Icons/HistoryIcon2';

const QuickLinks = ({ vrpConsentId, vrpType }) => {
    return (
        <IntlProvider locale="en">
            <nav className="Breadcrumbs">
                <Link to="/">Home</Link>
                <Link to="/services">Services</Link>
                <Link to={`/services/payHistory?consent-id=${vrpConsentId}&vrp-type=${vrpType}`}
                    className="active">PayHistory
                </Link>
            </nav>
        </IntlProvider>
    );
};

QuickLinks.propTypes = {
    vrpConsentId: PropTypes.string,
    vrpType: PropTypes.string,
};

const PayHistory = ({ vrpConsentData, vrpConsentId, vrpType }) => {
    const [payHistoryList, setPayHistoryList] = useState([]);

    const paymentStatus = status => {
        if (
            status === VRP_PAYMENT_STATUS.INITIATION_PROCESSING
            || status === VRP_PAYMENT_STATUS.INITIATION_COMPLETED
        ) {
            return (<span className='green'>{'Success'}</span>);
        } else {
            return (<span className='red'>{'Failed'}</span>);
        }
    };

    useEffect(
        () => {
            const vrps = vrpConsentData?.vrps;
            if (vrps) {
                setPayHistoryList(
                    Object.keys(vrps).map(k => {
                        return {
                            id: k,
                            ...vrps[k],
                        };
                    }),
                );
            } else {
                setPayHistoryList([]);
            }
        },
        [vrpConsentData],
    );

    if (payHistoryList && payHistoryList.length > 0) {
        return (
            <>
                <div className="Cart">
                    <QuickLinks vrpConsentId={vrpConsentId} vrpType={vrpType} />
                    <div className='PayHistory-list'>
                        <div className='PayHistory-list-title'>
                            {'Payment History'}
                        </div>
                        <table>
                            <thead>
                                <tr key='header'>
                                    <th>{'S. No.'}</th>
                                    <th>{'Card Nickname'}</th>
                                    <th>{'Reference Id'}</th>
                                    <th>{'Payment Date'}</th>
                                    <th>{'Payment Status'}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    payHistoryList.map((vrp, i) => {
                                        return (
                                            <tr className='vrp-row' key={vrp?.initiation?.refId || '----'}>
                                                <td>{(i + 1) + '.'}</td>
                                                <td>{vrpConsentData.accountNickName}</td>
                                                <td>{vrp?.initiation?.refId || '----'}</td>
                                                <td>
                                                    {
                                                        vrp?.createdDateTime
                                                            && new Date(vrp.createdDateTime)?.toUTCString()?.slice(0,16)
                                                            || '----'
                                                    }
                                                </td>
                                                <td>{paymentStatus(vrp.status)}</td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="Cart">
                <QuickLinks vrpConsentId={vrpConsentId} vrpType={vrpType} />
                <div className='NoHistory'>
                    <HistoryIcon2 className={'NoHistory-icon'} />
                    <div className='NoHistory-text'>{'No History'}</div>
                    <div className='NoHistory-sub-text'>{'Your payment history will appear here'}</div>
                </div>
            </div>
        </>
    );
};

PayHistory.propTypes = {
    vrpConsentData: PropTypes.object,
    vrpConsentId: PropTypes.string,
    vrpType: PropTypes.string,
};

const mapStateToProps = state => {
    const vrpConsentId = parseParamsFromUrl(window.location.href)?.['consent-id'];
    const vrpType = parseParamsFromUrl(window.location.href)?.['vrp-type'] || VRP_TYPE_SWEEPING;
    const vrpConsentData = vrpConsentId && state.vrp?.[vrpType]?.vrpConsentList
        && Object.keys(state.vrp?.[vrpType]?.vrpConsentList)
            .map(
                memberId => {
                    const consents = state.vrp?.[vrpType]?.vrpConsentList[memberId];
                    if (
                        consents[vrpConsentId]
                    ) {
                        return {
                            ...consents[vrpConsentId],
                        };
                    }
                },
            ).filter(v => Boolean(v));

    return {
        vrpConsentData: vrpConsentData && vrpConsentData[0] || {},
        vrpConsentId,
        vrpType,
    };
};

export default showIfLocation(connect(mapStateToProps)(PayHistory));
