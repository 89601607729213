import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FormattedNumber} from 'react-intl';
import cart from 'actions/cartActions';
import {currencySymbols, productCatalog} from 'config/constants';
import './StoreFront.css';
import {getCurrency} from 'reducers';
import {Link, showIfLocation} from 'SimpleHistory';
import {CLIENTS_URL_MAPPINGS} from '../../config/constants';
import {formatUrl} from '../../util';

class StoreFront extends React.Component {
    constructor(props) {
        super(props);
        this.items = productCatalog;
    }
    componentDidMount() {
        const {store} = this.context;
        this.isUnmounted = false;
        this.unsubscribe = store.subscribe(() => {
            if (!this.isUnmounted) {
                this.forceUpdate();
            }
        });
    }
    componentWillUnmount() {
        this.isUnmounted = true;
        this.unsubscribe();
    }

    onAddToCart(item) {
        const {store} = this.context;
        store.dispatch(cart.insert(item));
        this.props.history.push(formatUrl(this.props.clientName, '/cart'));
    }
    render() {
        const currency = currencySymbols[this.props.currency];
        const thumbs = this.items.map( item => {
            const boundAddToCart = this.onAddToCart.bind(this, item);
            const style = {
                thumb: {
                    backgroundImage: `url(${item.thumb})`,
                },
            };
            return (
                <div
                    key={item.id}
                    onClick={boundAddToCart}
                    className="StoreFront-item">
                    <div className="StoreFront-item-thumb">
                        <span className="StoreFront-item-image" style={style.thumb}></span>
                        <div className="StoreFront-item-addToCart">
                            <span>Add to cart</span>
                            <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10">
                                <path fill="#FFFFFF" d="M6 4V0H4v4H0v2h4v4h2V6h4V4H6z" />
                            </svg>
                        </div>
                    </div>
                    <div className="StoreFront-item-info">
                        <div className="StoreFront-item-price">
                            {currency} <FormattedNumber
                                value={this.props.testPricesEnabled ? item.testPrice : item.price}
                                minimumFractionDigits={2}
                                maximumFractionDigits={2}
                            />
                        </div>
                        <div className="StoreFront-item-title">{item.title}</div>
                    </div>
                </div>
            );
        });
        return (
            <div className="StoreFront">
                <nav className="Breadcrumbs">
                    <Link to="/">Home</Link>
                    <Link to="/" className="active">Shop All</Link>
                </nav>
                <div className="StoreFront-grid">
                    {thumbs}
                </div>
            </div>
        );
    }
}

StoreFront.contextTypes = {
    store: PropTypes.object,
};

StoreFront.propTypes = {
    testPricesEnabled: PropTypes.bool,
    currency: PropTypes.any,
    history: PropTypes.object,
    clientName: PropTypes.string,
    getClientUrlFromList: PropTypes.func,
};

const mapStateToProps = state => {
    return {
        testPricesEnabled: state.configMenu.customDestination.testPricesEnabled,
        currency: getCurrency(state),
        clientName: state.clientName,
    };
};

export default showIfLocation(connect(mapStateToProps)(StoreFront));
