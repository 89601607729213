import React, { useEffect, useState, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import style from './ConfirmationModal.css';
import Overlay from '@token-io/lib-web-components/src/Components/Overlay';
import {connect} from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import iconError from 'assets/icon-error.svg';

const cx = classNames.bind(style);

const INITIAL_MODAL_HEIGHT = 410;
const INITIAL_MODAL_WIDTH = 380;

// React Component
const ConfirmationModal = ({
    handleOverlay,
    handleConfirm,
}) => {
    const root = document.getElementById('app');
    const [showTransition, setShowTransition] = useState(false);
    const [modalHeight, setModalHeight] = useState(INITIAL_MODAL_HEIGHT);
    const [modalWidth, setModalWidth] = useState(INITIAL_MODAL_WIDTH);
    const [size, setSize] = useState(root.getBoundingClientRect());
    const [disabled, setDisabled] = useState(false);

    const handleDelete = async () => {
        setDisabled(true);
        const isSuccess = await handleConfirm();
        if (isSuccess) {
            handleOverlay(false);
        } else {
            handleOverlay(true);
        }
        setDisabled(false);
    };

    useLayoutEffect(() => {
        const updateSize = () => {
            if (window.innerWidth < 1169) {
                setModalWidth(300);
                setModalHeight(530);
            } else {
                setModalWidth(INITIAL_MODAL_WIDTH);
                setModalHeight(INITIAL_MODAL_HEIGHT);
            }
            setSize(root.getBoundingClientRect());
        };
        window.addEventListener('resize', updateSize);
        window.addEventListener('scroll', updateSize);
        updateSize();

        return () => {
            window.removeEventListener('resize', updateSize);
            window.addEventListener('scroll', updateSize);
        };
    }, []);

    useEffect(() => {
        setShowTransition(true);
    }, []);

    useEffect(() => {
        if (!showTransition) {
            const timeout = setTimeout(() => {
                handleOverlay(false);
            }, 600);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [showTransition]);

    return (
        <div>
            <CSSTransition
                classNames="ConfirmationModal-overlay-transition"
                in={!!showTransition}
                timeout={600}>
                <Overlay
                    overlayClass={cx('ConfirmationModal-overlay')}
                    portalClass={cx('ConfirmationModal-portal')}
                    rectPosition={{
                        left: Math.abs(window.innerWidth - modalWidth) / 2 - Math.abs(window.scrollX),
                        top: Math.abs(window.innerHeight - modalHeight) / 2 - Math.abs(window.scrollY),
                        height: modalHeight,
                        width: modalWidth,
                    }}
                >
                    { disabled && <div className="Animated yt-loader"></div> }
                    <div
                        className='ConfirmationModal'
                        style={{
                            height: modalHeight,
                            width: modalWidth,
                            ...(disabled && { opacity: 0.7 }),
                        }}
                    >
                        <img className='ConfirmationModal-icon-error' src={iconError} />
                        <div className='ConfirmationModal-title'>
                            {'Are you sure?'}
                        </div>
                        <div className='ConfirmationModal-sub-title'>
                            {'Do you really want to delete the record? This process cannot be undone.'}
                        </div>
                        <div className='ConfirmationModal-buttons-container'>
                            <div className='Button-container'>
                                <button
                                    disabled={disabled}
                                    onClick={() => handleOverlay(false)}
                                    className='ConfirmationModal-button-cancel'
                                >
                                    {'Cancel'}
                                </button>
                            </div>
                            <div className='Button-container'>
                                <button
                                    disabled={disabled}
                                    onClick={async () => await handleDelete()}
                                    className='ConfirmationModal-button-delete'
                                >
                                    {'Delete'}
                                </button>
                            </div>
                        </div>
                    </div>
                </Overlay>
            </CSSTransition>
        </div>
    );
};

ConfirmationModal.propTypes = {
    handleOverlay: PropTypes.func,
    handleConfirm: PropTypes.func,
};

const mapStateToProps = (state, ownProps) => {
    const handleOverlay = ownProps.handleOverlay;
    const handleConfirm = ownProps.handleConfirm;

    return {
        handleOverlay,
        handleConfirm,
    };
};

const mapDispatchToProps = {
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmationModal);
