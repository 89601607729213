import React from 'react';
import HistoryIconPng from 'assets/history.png';

const HistoryIcon = props => {

    return (
        <img src={HistoryIconPng} alt='' {...props} />
    );
};

export default HistoryIcon;
