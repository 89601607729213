import React from 'react';
import {render} from 'react-dom';
import {createStore} from 'redux';
import appState from 'reducers';
import {TOKEN_ENV} from 'config/constants';
import {loadState, saveState, syncLocalStorageVersion} from 'util.js';
import './main.css';
import 'assets/thumb1.jpg';
import 'assets/thumb2.jpg';
import 'assets/thumb3.jpg';
import 'assets/thumb4.jpg';
import 'assets/favicon.ico';
import 'assets/southside-avatar.png';
import 'assets/southside-logo.png';
import App from 'components/App';

if (!global.Intl) { // Polyfill for intl
    require('intl');
    require('intl/locale-data/jsonp/en.js');
}

syncLocalStorageVersion();

const persistedState = loadState();

let store;
if (TOKEN_ENV === 'local' || TOKEN_ENV === 'dev') {
    // Enable for development
    store = createStore(
        appState,
        persistedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
    // Enable for production
    store = createStore(appState, persistedState);
}

store.subscribe(() => {
    const state = store.getState();
    saveState({
        cart: state.cart,
        configMenu: state.configMenu,
        vrp: state.vrp,
    });
});

render(<App store={store} />,
    document.getElementById('app'),
);
