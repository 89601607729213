import React from 'react';
import PropTypes from 'prop-types';
import {FormattedNumber} from 'react-intl';
import {currencySymbols} from 'config/constants';

const CartGridEmptyRow = () => (
    <tr>
        <td
            colSpan={5}
            className="Cart-table-empty">
                Your cart is empty
        </td>
    </tr>
);

const CartGridRowData = ({
    item,
    onQtyChange,
    currency,
    testPricesEnabled,
}) => (
    <tr>
        <td className="Cart-table-thumb"><img src={item.thumb} /></td>
        <td className="Cart-table-desc">{item.title}</td>
        <td className="Cart-table-qty">
            <input
                onChange={evt => onQtyChange(item.id, evt.target.value)}
                type="text"
                defaultValue={item.qty}
            />
        </td>
        <td className="Cart-table-unit">{currencySymbols[currency]}
            <FormattedNumber
                value={testPricesEnabled ? item.testPrice : item.price}
                minimumFractionDigits={2}
                maximumFractionDigits={2} />
        </td>
        <td className="Cart-table-total">{currencySymbols[currency]}
            <FormattedNumber
                value={(testPricesEnabled ? item.testPrice : item.price) * item.qty}
                minimumFractionDigits={2}
                maximumFractionDigits={2} />
        </td>
    </tr>
);

CartGridRowData.propTypes = {
    item: PropTypes.object.isRequired,
    onQtyChange: PropTypes.func,
    currency: PropTypes.string.isRequired,
    testPricesEnabled: PropTypes.bool.isRequired,
};

CartGridRowData.defaultProps = {
    onQtyChange: () => {},
};

export {
    CartGridRowData,
    CartGridEmptyRow,
};
