import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {showIfLocation} from 'SimpleHistory';
import VRPSetupFormDefaults from '../../../VRPSetupFormDefaults';
import {connect} from 'react-redux';
import './VRPSetupForm.css';
import { PERIODIC_LIMITS, VRP_AMOUNT, currencySymbols, VRP_TYPE_SWEEPING, memberInfo } from 'config/constants.js';
import Dropdown from '@token-io/lib-web-components/src/Components/Dropdown';
import StaticDataProvider from '@token-io/lib-web-components/src/Components/Data/StaticDataProvider';
import { ROW_SIZE_STANDARD } from '@token-io/lib-web-components/src/Components/List/Abstract';
import { formatAsKeyValuePair, toIsoDate, formatCustomDestination, fetchSessionId } from 'util.js';
import TokenButton from 'components/TokenButton/TokenButtonVRP';
import VRPPaymentAccountDetails from '../VRPPaymentAccountDetails';
import Footer from '../../Footer';
import vrpActions from 'actions/vrp.js';

export const VRPSetupContext = React.createContext();
const frequencies = formatAsKeyValuePair(Object.keys(PERIODIC_LIMITS));

const formatFrequencyVal = defaultFrequency => {
    return frequencies.find(
        obj => (
            defaultFrequency
                ? PERIODIC_LIMITS[obj.value] === defaultFrequency
                : obj.value === 'MONTHLY'
        ),
    );
};

const VRPSetupForm = ({ customDestination, memberType, setVrpError, defaults }) => {
    const today = new Date();
    const yearAfterDate = new Date(today.getFullYear() + 1, today.getMonth(), today.getDate());
    const sessionId = fetchSessionId();

    // Defaults
    const {
        issuingBank: defaultIssuingBank = '',
        cardNumber: defaultCardNumber = '',
        accountNickName: defaultAccountNickName = '',
        frequency: defaultFrequency,
        amount: defaultAmount = 10,
    } = defaults;

    const [isReady, setIsReady] = useState(false);
    const [sourceDetailsReady, setSourceDetailsReady] = useState(false);
    const [cardNumber, setCardNumber] = useState(defaultCardNumber);
    const [issuingBank, setIssuingBank] = useState(defaultIssuingBank);
    const [accountNickName, setAccountNickName] = useState(defaultAccountNickName);
    const [startDate, setStartDate] = useState(toIsoDate(today));
    const [endDate, setEndDate] = useState(toIsoDate(yearAfterDate));
    const [frequency, setFrequency] = useState(formatFrequencyVal(defaultFrequency));
    const [amount, setAmount] = useState(VRP_AMOUNT.STATEMENT_BALANCE);
    const [customAmount, setCustomAmount] = useState(defaultAmount);
    const [destination, setDestination] = useState(null);
    const [paymentSourceDetails, setPaymentSourceDetails] = useState({});

    // Populate default values
    useEffect(
        () => {
            setCardNumber(defaultCardNumber);
            setIssuingBank(defaultIssuingBank);
            setAccountNickName(defaultAccountNickName);
            setFrequency(formatFrequencyVal(defaultFrequency));
            setCustomAmount(defaultAmount);
        },
        [defaults],
    );

    useEffect(
        () => {
            if (amount !== VRP_AMOUNT.CUSTOM) {
                setCustomAmount(10);
            }
        },
        [amount],
    );

    useEffect(
        () => {
            const dest = formatCustomDestination(
                customDestination.scheme,
                customDestination.account,
                memberType,
            )?.account?.[customDestination.scheme];
            setDestination(dest);
        },
        [customDestination, memberType],
    );

    useEffect(
        () => {
            if (
                cardNumber &&
                issuingBank &&
                accountNickName &&
                startDate &&
                endDate &&
                frequency &&
                amount &&
                destination && !Object.values(destination).some(x => !x) &&
                sourceDetailsReady
            ) {
                setIsReady(true);
            } else {
                setIsReady(false);
            }
        },
        [cardNumber, issuingBank, accountNickName, startDate, endDate, frequency, amount, destination, sourceDetailsReady],
    );

    const vrpFormData = {
        issuingBank: issuingBank,
        cardNumber: cardNumber,
        accountNickName: accountNickName,
        frequency: PERIODIC_LIMITS[frequency?.value],
        startDateTime: startDate,
        endDateTime: endDate,
        amount: customAmount,
        vrpType: VRP_TYPE_SWEEPING,
        destination,
        sessionId,
        ...paymentSourceDetails,
    };

    return (
        <VRPSetupContext.Provider value={{ setSourceDetailsReady, setPaymentSourceDetails }}>
            <div className='VRPSetupForm-container'>
                <div className='VRPSetup-title'>
                    Setup Auto Payment
                </div>
                <div className="VRPSetupForm-sub-container">
                    <div className='VRPSetupForm-content'>
                        <section className="VRPSetupForm">
                            <div className='VRPSetupForm-body'>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Card Number:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <input
                                            className='VRP-card-number'
                                            onChange={e => setCardNumber(e.target.value)}
                                            value={cardNumber}
                                        />
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Issuing Bank:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <input
                                            className='VRP-issuing-bank'
                                            onChange={e => setIssuingBank(e.target.value)}
                                            value={issuingBank}
                                        />
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Account Nickname:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <input
                                            className='VRP-account-nickname'
                                            onChange={e => setAccountNickName(e.target.value)}
                                            value={accountNickName}
                                        />
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Start Date:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <input
                                            className='VRP-start-date'
                                            type={'date'}
                                            value={new Date(startDate).toISOString().slice(0,10)}
                                            onChange={event => setStartDate(toIsoDate(new Date(event.target.value)))}
                                            min={new Date().toISOString().slice(0,10)}
                                        />
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        End Date:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <input
                                            className='VRP-end-date'
                                            type={'date'}
                                            value={new Date(endDate).toISOString().slice(0,10)}
                                            onChange={event => setEndDate(toIsoDate(new Date(event.target.value)))}
                                            min={startDate?.slice(0,10)}
                                            disabled={!startDate}
                                        />
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Frequency:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <StaticDataProvider
                                            source={() => frequencies}
                                            idKey={'id'}
                                            titleKey={'id'}
                                            dataKey={'value'}
                                            value={frequency}
                                            onChange={setFrequency}
                                            withPointer>
                                            <Dropdown
                                                className={'VRPSetupForm-frequency-dropdown'}
                                                size={ROW_SIZE_STANDARD}
                                            />
                                        </StaticDataProvider>
                                    </div>
                                </div>
                                <div className='VRPSetupForm-row'>
                                    <div className='VRPSetupForm-title'>
                                        Amount:
                                    </div>
                                    <div className='VRPSetupForm-detail'>
                                        <div className='VRPSetupForm-amount'>
                                            <div className='VRPSetupForm-amount-row'>
                                                <div className='VRPSetupForm-amount-title'>
                                                    <input
                                                        className='VRP-min-amount'
                                                        type='radio'
                                                        name='Vrp-amount'
                                                        onChange={() => setAmount(VRP_AMOUNT.MINIMUM_AMOUNT_DUE)}
                                                    />
                                                </div>
                                                <div className='VRPSetupForm-amount-detail'>
                                                    Minimum amount due
                                                </div>
                                            </div>
                                            <div className='VRPSetupForm-amount-row'>
                                                <div className='VRPSetupForm-amount-title'>
                                                    <input
                                                        className='VRP-amount-statement-bal'
                                                        type='radio'
                                                        name='Vrp-amount'
                                                        checked={amount === VRP_AMOUNT.STATEMENT_BALANCE}
                                                        onChange={() => setAmount(VRP_AMOUNT.STATEMENT_BALANCE)}
                                                    />
                                                </div>
                                                <div className='VRPSetupForm-amount-detail'>
                                                    Statement Balance
                                                </div>
                                            </div>
                                            <div className='VRPSetupForm-amount-row'>
                                                <div className='VRPSetupForm-amount-title'>
                                                    <input
                                                        className='VRP-amount-custom'
                                                        type='radio'
                                                        name='Vrp-amount'
                                                        onChange={() => setAmount(VRP_AMOUNT.CUSTOM)}
                                                    />
                                                </div>
                                                <div className='VRPSetupForm-amount-detail'>
                                                    Custom
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            amount === VRP_AMOUNT.CUSTOM
                                                ? (
                                                    <input
                                                        className='VRPSetupForm-custom-amount'
                                                        onChange={e => setCustomAmount(e.target.value)}
                                                        value={customAmount}
                                                    />
                                                ) : null
                                        }
                                    </div>
                                </div>
                                <VRPPaymentAccountDetails vrpType={VRP_TYPE_SWEEPING} />
                            </div>
                        </section>
                    </div>
                    <div className="VRPSetupForm-summary">
                        <div className="VRPSetupForm-summary-panel">
                            <div className="VRPSetupForm-summary-row">
                                <div className='VRPSetupForm-summary-row-title'>
                                    Frequency:
                                </div>
                                <div className='VRPSetupForm-summary-row-detail'>
                                    {
                                        !frequency
                                            ? '-----'
                                            : frequency?.value
                                    }
                                </div>
                            </div>
                            <div className="VRPSetupForm-summary-row">
                                <div className='VRPSetupForm-summary-row-title'>
                                    Start date:
                                </div>
                                <div className='VRPSetupForm-summary-row-detail'>
                                    {
                                        !startDate
                                            ? '-----'
                                            : new Date(startDate)?.toUTCString().slice(0,16)
                                    }
                                </div>
                            </div>
                            <div className="VRPSetupForm-summary-row">
                                <div className='VRPSetupForm-summary-row-title'>
                                    End date:
                                </div>
                                <div className='VRPSetupForm-summary-row-detail'>
                                    {
                                        !endDate
                                            ? '-----'
                                            : new Date(endDate)?.toUTCString().slice(0,16)
                                    }
                                </div>
                            </div>
                            <div className="VRPSetupForm-summary-row green">
                                <div className='VRPSetupForm-summary-row-title'>
                                    Amount:
                                </div>
                                <div className='VRPSetupForm-summary-row-detail'>
                                    {
                                        customAmount
                                            ? customAmount + ' ' + currencySymbols[paymentSourceDetails.currency]
                                            : '-----'
                                    }
                                </div>
                            </div>
                            <div className='VRPSetupForm-token-button-container'>
                                <TokenButton
                                    text='Setup Now'
                                    onError={err => setVrpError(err)}
                                    data={vrpFormData}
                                    isReady={isReady}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </VRPSetupContext.Provider>
    );
};

VRPSetupForm.propTypes = {
    customDestination: PropTypes.object,
    memberType: PropTypes.string,
    setVrpError: PropTypes.func,
    defaults: PropTypes.object,
};

const mapStateToProps = state => {
    const memberType = state.configMenu.memberType;
    const clientName = state.clientName;
    const memberId = memberInfo[clientName][memberType]['id'];
    return {
        customDestination: state.configMenu.customDestination,
        memberType,
        memberId,
    };
};

const mapDispatchToProps = {
    setVrpError: vrpActions.setVrpError,
};

const VRPSetupFormWithDefaults = props => {
    return (
        <VRPSetupFormDefaults>
            <VRPSetupForm {...props} />
        </VRPSetupFormDefaults>
    );
};

export default showIfLocation(connect(mapStateToProps, mapDispatchToProps)(VRPSetupFormWithDefaults));
