const configMenu = {
    changeBank: bank =>
        ({
            type: 'CHANGE_DESTINATION_BANK',
            bank,
        }),
    changeMethod: method =>
        ({
            type: 'CHANGE_DESTINATION_METHOD',
            method,
        }),
    changeCountry: country =>
        ({
            type: 'CHANGE_DESTINATION_COUNTRY',
            country,
        }),
    changeCurrency: currency =>
        ({
            type: 'CHANGE_DESTINATION_CURRENCY',
            currency,
        }),
    setWebAppEnabled: webAppEnabled =>
        ({
            type: 'SET_WEB_APP_ENABLED',
            webAppEnabled,
        }),
    setCustomDestinationAccount: account =>
        ({
            type: 'SET_CUSTOM_DESTINATION_ACCOUNT',
            account,
        }),
    setCustomDestinationCurrency: currency =>
        ({
            type: 'SET_CUSTOM_DESTINATION_CURRENCY',
            currency,
        }),
    setCustomDestinationScheme: scheme =>
        ({
            type: 'SET_CUSTOM_DESTINATION_SCHEME',
            scheme,
        }),
    setDevKey: devKey =>
        ({
            type: 'SET_DEV_KEY',
            devKey,
        }),
    setSourceCountry: country =>
        ({
            type: 'SET_SOURCE_COUNTRY',
            country,
        }),
    setTestPrices: testPricesEnabled =>
        ({
            type: 'SET_TEST_PRICES',
            testPricesEnabled,
        }),
    setAdvancedConfig: () =>
        ({
            type: 'SET_ADVANCED_CONFIG',
        }),
    setSourceAccount: ( sourceAccount, scheme = '', bankId = '' ) =>
        ({
            type: 'SET_SOURCE_ACCOUNT',
            sourceAccount,
            scheme,
            bankId,
        }),
    setBetaVersion: betaVersionEnabled =>
        ({
            type: 'SET_BETA_VERSION',
            betaVersionEnabled,
        }),
    setWebappInIframe: webappInIframe =>
        ({
            type: 'SET_WEB_APP_IN_IFRAME',
            webappInIframe,
        }),
    setWebappV2Modal: webappV2Modal =>
        ({
            type: 'SET_WEB_APP_V2_MODAL',
            webappV2Modal,
        }),
    setCafEnabled: isCafEnabled =>
        ({
            type: 'SET_CAF_ENABLED',
            isCafEnabled,
        }),
    setMemberType: memberType =>
        ({
            type: 'SET_MEMBER_TYPE',
            memberType,
        }),
    setDemoClientName: name =>
        ({
            type: 'SET_DEMO_CLIENT_NAME',
            name,
        }),
    setCredentials: credentials =>
        ({
            type: 'SET_CREDENTIALS',
            credentials,
        }),
    setSelectedBankInfo: bankInfo =>
        ({
            type: 'SET_SELECTED_BANK_INFO',
            bankInfo,
        }),
    setProviderDetails: details =>
        ({
            type: 'SET_PROVIDER_DETAILS',
            details,
        }),
    setRemittanceReference: value =>
        ({
            type: 'SET_REMITTANCE_REFERENCE',
            value,
        }),
};

export default configMenu;
