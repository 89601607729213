import React from 'react';
import HistoryIconPng from 'assets/history2.png';

const HistoryIcon2 = props => {

    return (
        <img src={HistoryIconPng} alt='' {...props} />
    );
};

export default HistoryIcon2;
