import React from 'react';
import PropTypes from 'prop-types';
import {showIfLocation} from 'SimpleHistory';

import {CSSTransition, TransitionGroup} from 'react-transition-group';

class CartDetail extends React.Component {
    getChildren() {
        const {history} = this.props;
        const path = history.location.pathname;
        return this.props.children &&
        <CSSTransition
            key={path}
            classNames="pageFade"
            timeout={{enter: 800, exit: 800}}>
            <React.Fragment>
                {this.props.children}
            </React.Fragment>
        </CSSTransition>;
    }
    render() {
        return (
            <div className="CartDetail">
                <TransitionGroup>
                    {this.getChildren()}
                </TransitionGroup>
            </div>
        );
    }
}

CartDetail.propTypes = {
    children: PropTypes.node,
    history: PropTypes.object,
};

export default showIfLocation(CartDetail);
