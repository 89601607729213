import React from 'react';
import PropTypes from 'prop-types';
import {connect, Provider} from 'react-redux';
import {IntlProvider} from 'react-intl';
import Outer from 'components/Pages';
import StoreFront from 'components/Pages/StoreFront';
import AdhocPayForm from 'components/Pages/Vrp/AdhocPay/AdhocPayForm';
import SubscriptionSetup from 'components/Pages/Vrp/NonSweeping/SubscriptionSetup';
import AutoPaySetup from 'components/Pages/Vrp/Sweeping/AutoPaySetup';
import AdhocPayError from 'components/Pages/Vrp/AdhocPay/AdhocPayError';
import AdhocPaySuccess from 'components/Pages/Vrp/AdhocPay/AdhocPaySuccess';
import VRPSetupSuccess from 'components/Pages/Vrp/VRPSetupSuccess';
import VRPSetupError from 'components/Pages/Vrp/VRPSetupError';
import Services from 'components/Pages/Vrp/Services';
import Cart from 'components/Pages/Cart';
import VRPCronJob from 'components/Pages/Vrp/VRPCronJob';
import CartDetail from 'components/Pages/CartDetail';
import CheckoutEnd from 'components/Pages/CheckoutEnd';
import SufficientFunds from 'components/Pages/SufficientFunds';
import InsufficientFunds from 'components/Pages/InsufficientFunds';
import PayHistory from 'components/Pages/Vrp/PayHistory';
import StandardCheckout from 'components/Pages/StandardCheckout';
import Error from 'components/Pages/Error';
import {withHistory} from 'SimpleHistory';
import {CLIENTS_URL_MAPPINGS, hotModule} from 'config/constants';
import configMenuActions from '../../actions/configMenuActions';

class App extends React.Component {

    render() {
        const pathValue = window.location.pathname.split('/')[1];
        let clientName;
        let clientUrl;

        if (Object.values(CLIENTS_URL_MAPPINGS).includes(pathValue)) {
            clientName = Object.keys(CLIENTS_URL_MAPPINGS).find(k => CLIENTS_URL_MAPPINGS[k] === pathValue);
            this.props.setDemoClientName(clientName);
        } else {
            this.props.setDemoClientName('southside');
        }
        const clientBaseUrl = CLIENTS_URL_MAPPINGS[clientName];

        return (
            <Provider store={this.props.store}>
                <VRPCronJob>
                    <IntlProvider locale="en">
                        {clientBaseUrl && clientBaseUrl !== '' ?
                            (
                                <Outer path={`^\\/${clientBaseUrl}`}>
                                    <StoreFront path={`^\\/${clientBaseUrl}$`}></StoreFront>
                                    <CartDetail path={`^\\/${clientBaseUrl}/cart`}>
                                        <Cart path={`^\\/${clientBaseUrl}/cart$`}></Cart>
                                        <CheckoutEnd
                                            path={`^\\/${clientBaseUrl}/cart/thankyou`}>
                                        </CheckoutEnd>
                                        <SufficientFunds
                                            path={`^\\/${clientBaseUrl}/cart/fundsConfirmed`}>
                                        </SufficientFunds>
                                        <InsufficientFunds
                                            path={`^\\/${clientBaseUrl}/cart/insufficientFunds`}>
                                        </InsufficientFunds>
                                        <Error path={`^\\/${clientBaseUrl}/cart/error`}></Error>
                                        <StandardCheckout
                                            path={`^\\/${clientBaseUrl}/cart/checkout`}>
                                        </StandardCheckout>
                                    </CartDetail>
                                    <Services path={`^\\/${clientBaseUrl}/services$`}></Services>
                                    <AutoPaySetup path={`^\\/${clientBaseUrl}/services/sweeping/autopay/setup$`}></AutoPaySetup>
                                    <VRPSetupSuccess path={`^\\/${clientBaseUrl}/services/vrp/setup/success`}></VRPSetupSuccess>
                                    <VRPSetupError path={`^\\/${clientBaseUrl}/services/vrp/setup/error`}></VRPSetupError>
                                    <SubscriptionSetup path={`^\\/${clientBaseUrl}/services/nonSweeping/autopay/setup$`}></SubscriptionSetup>
                                    <PayHistory path={`^\\/${clientBaseUrl}/services/payhistory`}></PayHistory>
                                    <AdhocPayForm path={`^\\/${clientBaseUrl}/services/adhocpay$`}></AdhocPayForm>
                                    <AdhocPaySuccess path={`^\\/${clientBaseUrl}/services/adhocpay/success`}></AdhocPaySuccess>
                                    <AdhocPayError path={`^\\/${clientBaseUrl}/services/adhocpay/error`}></AdhocPayError>
                                </Outer>
                            )
                            :
                            (
                                <Outer path={'^\\/'}>
                                    <StoreFront path={'^\\/$'}></StoreFront>
                                    <CartDetail path={'^\\/cart'}>
                                        <Cart path={'^\\/cart$'}></Cart>
                                        <CheckoutEnd
                                            path={'^\\/cart/thankyou'}>
                                        </CheckoutEnd>
                                        <SufficientFunds
                                            path={'^\\/cart/fundsConfirmed'}>
                                        </SufficientFunds>
                                        <InsufficientFunds
                                            path={'^\\/cart/insufficientFunds'}>
                                        </InsufficientFunds>
                                        <Error path={'^\\/cart/error'}></Error>
                                        <StandardCheckout
                                            path={'^\\/cart/checkout'}>
                                        </StandardCheckout>
                                    </CartDetail>
                                    <Services path={'^\\/services$'}></Services>
                                    <AutoPaySetup path={'^\\/services/sweeping/autopay/setup$'}></AutoPaySetup>
                                    <VRPSetupSuccess path={'^\\/services/vrp/setup/success'}></VRPSetupSuccess>
                                    <VRPSetupError path={'^\\/services/vrp/setup/error'}></VRPSetupError>
                                    <SubscriptionSetup path={'^\\/services/nonSweeping/autopay/setup$'}></SubscriptionSetup>
                                    <PayHistory path={'^\\/services/payhistory'}></PayHistory>
                                    <AdhocPayForm path={'^\\/services/adhocpay$'}></AdhocPayForm>
                                    <AdhocPaySuccess path={'^\\/services/adhocpay/success'}></AdhocPaySuccess>
                                    <AdhocPayError path={'^\\/services/adhocpay/error'}></AdhocPayError>
                                </Outer>
                            )}
                    </IntlProvider>
                </VRPCronJob>
            </Provider>
        );
    }
}

App.propTypes = {
    store: PropTypes.object,
    setDemoClientName: PropTypes.func,
};

const mapDispatchToProps = {
    setDemoClientName: configMenuActions.setDemoClientName,
};

const HistoryApp = withHistory(connect(null,mapDispatchToProps)(App));
export default hotModule
    && require('react-hot-loader').hot(module)(HistoryApp)
    || HistoryApp;
