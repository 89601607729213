import React from 'react';
import PropTypes from 'prop-types';
import TokenButtonV2 from './TokenButtonV2';
import TokenButtonV1 from './TokenButtonV1';

const TokenEnablerButton = ({
    webappV2Modal,
    ...props
}) => {
    if (webappV2Modal) {
        return <TokenButtonV2 {...props}></TokenButtonV2>;
    }

    return <TokenButtonV1 {...props}></TokenButtonV1>;
};

TokenEnablerButton.propTypes = {
    webappV2Modal: PropTypes.bool,
};

export default TokenEnablerButton;
