import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import DestinationMenu from 'components/DestinationMenu';
import CustomDestinationInput from 'components/CustomDestinationInput';
import './index.css';
import {connect} from 'react-redux';
import MenuDrawer from 'components/MenuDrawer';
import configMenuActions from 'actions/configMenuActions';
import {showIfLocation, Link} from 'SimpleHistory';
import 'assets/breadcrumbs-divider.png';
import {CLIENTS_LOGO_MAPPINGS} from '../../config/constants';

const Links = () => (
    <nav className="header-menu">
        <Link
            to="/"
            className="header-menu-item header-menu-item--active"
            data-close
        >HOME
        </Link>
        <a href="#" className="header-menu-item">SHOP</a>
        <a href="/services" className="header-menu-item">SERVICES</a>
        <a href="#" className="header-menu-item">MARKETPLACE</a>
        <a href="#" className="header-menu-item">NEWS</a>
        <a href="#" className="header-menu-item">POLICY</a>
        <a href="#" className="header-menu-item">CONTACT US</a>
        <Link
            to="/cart"
            className="header-menu-item emphasis"
            data-close>CART
        </Link>
    </nav>
);

const Outer = ({
    children,
    configMenu: {
        advanced,
        bank,
        method,
        country,
        currency,
        webAppEnabled,
        source,
    },
    onSelectedBank,
    onSelectedMethod,
    onSelectedCountry,
    onSelectedCurrency,
    setWebAppEnabled,
    setAdvancedConfig,
    setSourceAccount,
    clientName,
}) => {
    const logo = CLIENTS_LOGO_MAPPINGS[clientName];
    useEffect(() => {
        window.document.getElementById('tokenWebAppIframe').addEventListener('DOMNodeInserted', () => {
            window.document.getElementById('tokenWebAppIframe').focus();
        }, false );
    }, []);

    const destinationMenu = advanced ? <CustomDestinationInput /> : (
        <DestinationMenu
            onSelectedBank={onSelectedBank}
            onSelectedMethod={onSelectedMethod}
            onSelectedCountry={onSelectedCountry}
            onSelectedCurrency={onSelectedCurrency}
            setWebAppEnabled={setWebAppEnabled}
            setAdvancedConfig={setAdvancedConfig}
            setSourceAccount={setSourceAccount}
            bank={bank}
            method={method}
            country={country}
            currency={currency}
            webAppEnabled={webAppEnabled}
            sourceBankId={source.bankId}
        />
    );
    return (
        <div>
            <header>
                <div className="header-logo">
                    <div className="header-wrapper">
                        <Link to="/"><img src={logo}
                            alt="SOUTHSIDE" className="logo" />
                        </Link>
                    </div>
                    {destinationMenu}
                </div>
                <div className="menu-bg">
                    <Links />
                </div>
                <MenuDrawer iconColor="#56c03d">
                    <Links />
                </MenuDrawer>
            </header>
            <div className='Token-webapp-container'>
                <div id="tokenWebAppIframe" tabIndex='0'></div>
            </div>
            <main role="main">
                {children}
            </main>
        </div>
    );
};

Outer.propTypes = {
    children: PropTypes.node,
    onSelectedBank: PropTypes.func.isRequired,
    onSelectedMethod: PropTypes.func.isRequired,
    onSelectedCountry: PropTypes.func.isRequired,
    onSelectedCurrency: PropTypes.func.isRequired,
    setWebAppEnabled: PropTypes.func.isRequired,
    setAdvancedConfig: PropTypes.func.isRequired,
    setSourceAccount: PropTypes.func.isRequired,
    configMenu: PropTypes.object.isRequired,
    clientName: PropTypes.string,
};

Outer.contextTypes = {
    store: PropTypes.object,
};

const mapStateToProps = state => ({
    configMenu,
}) => ({
    configMenu,
    clientName: state.clientName,
});

const mapDispatchToProps = dispatch => ({
    onSelectedBank: bank => {
        if (!bank) return;
        dispatch(configMenuActions.changeBank(bank));
    },
    onSelectedMethod: method => {
        if (!method) return;
        dispatch(configMenuActions.changeMethod(method));
    },
    onSelectedCountry: country => {
        if (!country) return;
        dispatch(configMenuActions.changeCountry(country));
    },
    onSelectedCurrency: currency => {
        if (!currency) return;
        dispatch(configMenuActions.changeCurrency(currency));
    },
    setWebAppEnabled: webAppEnabled => dispatch(configMenuActions.setWebAppEnabled(webAppEnabled)),
    setAdvancedConfig: () => dispatch(configMenuActions.setAdvancedConfig()),
    setSourceAccount: (account, scheme, bankId) => dispatch(configMenuActions.setSourceAccount(account, scheme, bankId)),
});

export default showIfLocation(connect(
    mapStateToProps,
    mapDispatchToProps,
)(Outer));
