import React from 'react';
import {createBrowserHistory} from 'history';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {formatUrl} from './util';

const history = createBrowserHistory();
const HistoryContext = React.createContext(history);

export const withHistory = Component => function HistoryComponent(props) {
    return (
        <HistoryContext.Consumer>
            {history => <Component {...props} history={history} />}
        </HistoryContext.Consumer>
    );
};

export const showIfLocation = Component => {
    class Sub extends React.Component {
        isPath() {
            const {path, history} = this.props;
            const regex = new RegExp(path);
            return regex && regex.test(history.location.pathname);
        }
        componentDidMount() {
            const {history} = this.props;
            this.unlisten = history.listen(location => {
                this.forceUpdate();
            });
        }
        componentWillUnmount() {
            this.unlisten();
        }
        render() {
            return this.isPath()
                ? <Component {...this.props} />
                : null;
        }
    }
    Sub.propTypes = {
        path: PropTypes.string,
        history: PropTypes.object,
    };
    return withHistory(Sub);
};

const mapStateToProps = state => {
    return {
        clientName: state.clientName,
    };
};

export const Link = connect(mapStateToProps, {})(({to, clientName, children, ...props}) => {
    return (
        <HistoryContext.Consumer>
            {history => (
                <a href={formatUrl(clientName,to)}
                    onClick={e => {
                        e.preventDefault();
                        history.push(formatUrl(clientName,to));
                    }}
                    {...props}
                >
                    {
                        children && (React.isValidElement(children) ? React.cloneElement(children) : children)
                    }
                </a>
            )}
        </HistoryContext.Consumer>
    );
});

Link.propTypes = {
    to: PropTypes.string,
    children: PropTypes.any,
    clientName: PropTypes.string,
};
